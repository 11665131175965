import React, { ReactElement, useState } from "react";
import AuthService from "../../services/AuthService";
import withNoAuth from "../../hocs/withNoAuth";
import logo from "../../assets/yaldo_icon-text_white.svg";
import { useNavigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import CSS from "csstype";
import { ToastMessageType, createToast } from "../../components/Notifications";

function LoginScene(): ReactElement {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [errorEmail, setErrorEmail] = useState<string | null>(null);
  const [errorPassword, setErrorPassword] = useState<string | null>(null);
  const [errorGlobal, setErrorGlobal] = useState<string | string[] | null>(
    null
  );
  const [login, setLogin] = useState<boolean>(false);
  const navigate = useNavigate();
  const redirectTo = new URLSearchParams(useLocation().search).get("path");

  const validateAndSendForm = async (e: any) => {
    e.preventDefault();
    setErrorEmail(null);
    setErrorPassword(null);
    setErrorGlobal(null);
    let re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let errorEmailLocal = !email.length || !re.test(email);
    let errorPasswordLocal = !password;
    if (errorEmailLocal) {
      setErrorEmail("Gelieve een geldig e-mailadres in te geven.");
    }

    if (errorPasswordLocal) {
      setErrorPassword("Gelieve een geldig wachtwoord in te geven.");
    }
    if (!errorEmailLocal && !errorPasswordLocal) {
      try {
        const res = await AuthService.login(email, password);
        console.log(res);
        setLogin((login) => !login);
        console.log(login, "Succesfull");
        console.log(login);
        if (redirectTo) {
          navigate(redirectTo);
        }
        window.location.reload();
        navigate("/");
      } catch (e: any) {
        setLogin(false);
        setEmail("");
        setPassword("");
        setErrorGlobal(e.message);
        createToast("Inloggen mislukt!", ToastMessageType.Error);
      }
    }
  };

  const wrapper: CSS.Properties = {
    width: "100%",
    height: "100vh",
    display: "flex",
  };

  const authSection: CSS.Properties = {
    width: "60%",
    height: "100vh",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
  };

  const contentContainer: CSS.Properties = {
    width: "65%",
    margin: "20% auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  return (
    <div style={wrapper} >
      <aside
        style={{
          background: "linear-gradient(to top right, #19005E, #361692)",
          width: "40%",
          color: "white",
          borderRadius: "0 2% 2% 0",
          display: "flex",
        }}
      >
        <div style={{ width: "80%", marginLeft: "auto", paddingTop: "20%" }}>
          <img src={logo} alt="Yaldo logo" width="200px" className="mb-6" />
          <h1
            style={{
              margin: 0,
              marginTop: "20%",
              fontSize: "3em",
              lineHeight: "1.5em",
            }}
          >
            Experience more, <br /> together
          </h1>
        </div>
      </aside>

      <main style={authSection} className="relative">
        <div className="content" style={contentContainer}>
        <h1
  className="text-center"
  style={{
    margin: 0,
    width: "100%",
    fontFamily: "regular",
    marginBottom: "10px", // Use px for spacing
  }}
>
  Log in to your{" "}
  <b
    style={{
      fontFamily: "bold",
      fontSize: "1em",
      color: "#2D0F81",
      marginLeft: "5px", // Add left margin for spacing
      marginRight: "5px", // Add right margin for spacing
    }}
  >
    Yaldo Experience Creator{" "}
  </b>
  account
</h1>

          <form onSubmit={validateAndSendForm} className="max-w-[70%] w-[100%]">
            <div className="mb-4 w-full">
              <label
                htmlFor="email"
                style={{ fontFamily: "regular", color: "#555568" }}
              >
                Email*
              </label>
              <input
                style={{
                  borderColor: "#979bb5",
                  borderWidth: 1,
                  borderRadius: 16,
                  padding: 10,
                  paddingLeft: 30,
                  height: 60,
                  width: "100%",
                  marginTop: 6,
                  fontFamily: "regular",
                  fontSize: "0.95em",
                  color: "#818194",
                }}
                id="text"
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <span className="text-error text-xs">{errorEmail}</span>
            </div>
            <div className="mt-8 w-full mb-16">
              <label
                htmlFor="password"
                style={{ fontFamily: "regular", color: "#555568" }}
              >
                Password*
              </label>
              <input
                style={{
                  borderColor: "#979bb5",
                  borderWidth: 1,
                  borderRadius: 16,
                  padding: 10,
                  paddingLeft: 30,
                  height: 60,
                  width: "100%",
                  marginTop: 6,
                  fontFamily: "regular",
                  fontSize: "0.95em",
                  color: "#818194",
                }}
                id="password"
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div
                className="mt-4 cursor-pointer"
                style={{
                  fontFamily: "regular",
                  color: "#555568",
                  float: "right",
                }}
              >
                {" "}
                Forgot Password?
              </div>
              <span className="text-error text-xs">{errorPassword}</span>
            </div>
            <div className="flex justify-center">
              <button
                className="w-[100%]"
                style={{
                  color: "white",
                  borderRadius: "15px",
                  backgroundColor: "#19005E",
                  padding: "15px 0",
                }}
                type="submit"
                onClick={validateAndSendForm}
              >
                Log In
              </button>
            </div>
          </form>
          <div
            className="flex items-center justify-between"
            style={{
              position: "absolute",
              bottom: "2%",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Link
              to="/signup"
              style={{
                fontFamily: "regular",
                color: "#555568",
                textAlign: "center",
                paddingTop: "5%",
                paddingBottom: "10%",
              }}
            >
              Don’t have an account? <u style={{ color: "#2D1E81" }}>Sign Up</u>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
}

export default withNoAuth(LoginScene);
