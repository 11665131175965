import { Suspense, useEffect, useRef, useState } from 'react';
import { Buffer } from 'buffer';
import CSS from 'csstype';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import yaldoIcon from "../../assets/yaldo_icon-text_white.svg";
import ProgresBar from '../../components/ProgressBar';
import withNoAuth from '../../hocs/withNoAuth';
import WaitingScreenScene from '../waitingScreen/WaitingScreenScene';

import { Colors } from '../../components/Colors';
import AuthService from '../../services/AuthService';
import { UserSignUpDto } from '../../dto/UserSignUp.dto';
import StoreSignUpDto from '../../dto/StoreSignUp.dto';
import { Role } from '../../dto/Role.dto';
import { getAddressCoordinates, setBearerToken } from '../../services/HttpClients';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import { CreateClosedPeriodDto, CreateDayOpeningHoursDto, CreateUserAvailability, UserAvailabilityFormState } from '../../dto/UserAvailability.dto';
import UserService from '../../services/UserService';
import StoreService from '../../services/StoreService';
import ConfirmCancelDialog from '../../components/YesNoDialog';
import { ToastMessageType, createToast } from '../../components/Notifications';
import { MollieLinkSubscriptionDto } from '../../dto/LinkSubscriptionPlan.dto';

interface ILocalData{
  step: number;
  userFormData: UserSignUpDto;
  storeFormData: StoreSignUpDto;
  userAvailabilityData: StoreSignUpDto;
  bearerToken?: string;
}

function Signup(){
    const navigate = useNavigate();
    let bearerToken: string;
    const showValidationErrorsRef = useRef<(value: boolean) => void>(() => {});
  
    const currentStep = 1;
    
    const [continueSignup, setContinue] = useState(false);
    const [step, setStep] = useState(currentStep);
    const [isFormValid, setIsFormValid] = useState(false);
    const submitFormRef = useRef<() => void>(() => {});

    const [userFormData, setUserFormData] = useState<UserSignUpDto>({
      firstName: '',
      lastName: '',
      phone: '',
      dateOfBirth: '',
      email: '',
      password: '',
      address: '',
      country: '',
      role: Role.STORE_OWNER,
      preferedEmotions: [],
    });

    const [storeFormData, setStoreFormData] = useState<StoreSignUpDto>({
      yaldoName: '',
      companyName: '',
      country: '',
      address: '',
      iban: '',
      vat: '',
      bicc: '',
      companyRepresentative: '',
      lat: '',
      lon: '',
      ownerIdImg: [],
      proofImg: [],
      idImg: []
    });

    const [userAvailabilityForm, setUserAvailabilityForm] = useState<UserAvailabilityFormState>({
      available: new Map<string, CreateDayOpeningHoursDto>(),
      unavailable: new Map<string, CreateClosedPeriodDto>(),
    });
    
    useEffect(() => {
      const localData = localStorage.getItem("tempUserStoreData")
      if(localData){
        const tempUserData: ILocalData = JSON.parse(localData);
        console.log(tempUserData)
        setStep(tempUserData.step+1);
        setUserFormData(tempUserData.userFormData)
        setStoreFormData(tempUserData.storeFormData)
  /*       setUserAvailabilityForm({
          available: new Map(tempUserData.available),
          unavailable: new Map(),
        }) */
      }
    }, [])

    async function dontContinueSignup(){
      setContinue(false);
      const localUser = getLocalTempUser();
      localStorage.removeItem("tempUserStoreData")
      navigate('/signup')
      //todo add delete user
      await AuthService.cancelSignUp(localUser?.bearerToken!);
    }

    function getLocalTempUser(){
      const localData = localStorage.getItem("tempUserStoreData")
      if(localData){
        const tempUserData: ILocalData = JSON.parse(localData);
        return tempUserData;
      }
    }

    useEffect(() => {
        const tempUserData = getLocalTempUser();
        if(tempUserData){
          setContinue(true);
          console.log(tempUserData)
          setStep(tempUserData.step+1);
          setUserFormData(tempUserData.userFormData)
          setStoreFormData(tempUserData.storeFormData)
        } else setStep(currentStep);
        
        //setStep(currentStep);

        console.log('Signup mounted');
        return () => console.log('Signup unmounted');
      }, [currentStep]);
      

      const handleFormValidation = (isValid: boolean) => {
        setIsFormValid(isValid);
      };
    
      const handleNextStep = async () => {
        if (isFormValid) {
          const nextStep = step + 1;
          setStep(nextStep);
          navigate(`/signup/step${nextStep}`);
          switch(step){
            case 2: {
              const userExists = await AuthService.checkExistingUser(userFormData);
              if(userExists){
                createToast(`User with email ${userFormData.email} already exists`, ToastMessageType.Error);
                setStep(nextStep-1);
                navigate(`/signup/step${nextStep-1}`);
              }
              break;
            }
            case 3: {
              if (userFormData){
                try{
                  bearerToken = await AuthService.signUp(userFormData);
                  localStorage.setItem("tempUserStoreData", JSON.stringify({
                    step: step,
                    userFormData: userFormData,
                    storeFormData: storeFormData,
                    bearerToken: bearerToken
                  }));
                  //console.log("bearer token", bearerToken);
                  setBearerToken(bearerToken);
                  const [header, payload, signature] = bearerToken.split('.');
                  const payloadBase64 = payload.replace(/-/g, '+').replace(/_/g, '/');
                  const payloadBuffer = Buffer.from(payloadBase64, 'base64');
                  const payloadObject = JSON.parse(payloadBuffer.toString('utf8'));
                  console.log("This is the user's access code that was just created: ", payloadObject, " ID: ", payloadObject.id);
                  
                  setStoreFormData((prevData) => ({
                    ...prevData,
                    ownerId: payloadObject.id,
                  }));  
                  //console.log("user information set correctly");
               }
               catch(err) {
                console.log(err)
                 createToast("Er ging iets mis bij het ophalen van de masterdata.", ToastMessageType.Error);
                }
              } else{
                console.log("User form data is empty");
              }
              break;
            }
            case 4: {
                console.log("Submitting store form data:", storeFormData);
                if(storeFormData) {
                  try{
                    const localUser = getLocalTempUser();
                    localStorage.setItem("tempUserStoreData", JSON.stringify({
                      step: step,
                      userFormData: userFormData,
                      storeFormData: storeFormData,
                      bearerToken: localUser?.bearerToken
                    }));
                    const storeSignupResponse = await AuthService.storeSignUp(storeFormData);
                    console.log(storeSignupResponse)
                    //update store id in current form
                    setStoreFormData((prevData) => ({
                      ...prevData,
                      id: storeSignupResponse?.id,
                    })); 
                  }
                  catch(err) {
                    createToast("There was a problem creating your store account", ToastMessageType.Error);
                  }
                }
                break;
            }
            case 5: {
              console.log("Submitting availability...")
              const dataPayload = {
                'available': Array.from(userAvailabilityForm.available.values()),
                'unavailable': Array.from(userAvailabilityForm.unavailable.values())
              }
              const localUser = getLocalTempUser();
              if(localUser){
                localStorage.setItem("tempUserStoreData", JSON.stringify({
                  step: step,
                  userFormData: userFormData,
                  storeFormData: storeFormData,
                  userAvailabilityData: dataPayload,
                  bearerToken: bearerToken
                }));
              }
              const responseData = await UserService.createAvailability(dataPayload as CreateUserAvailability);
              console.log(responseData);
              break;
            }
            case 6: {
              console.log(`Step ${step}`)
              const { idImg, proofImg, ownerIdImg, id } = storeFormData;
              console.log(storeFormData)
              if(!idImg || !proofImg || !ownerIdImg || !id){
                return createToast("Please add the necessary documents", ToastMessageType.Error)
              } 
              //const verificationData = { userVerification: idImg, companyVerification: proofImg, bankingVerification: ownerIdImg, date: new Date(Date.now()).toISOString(), id: id||20 }
              const payloadData = new FormData();
              payloadData.append('id', id!.toString());
              payloadData.append('date', new Date(Date.now()).toISOString());
              idImg.forEach((file, index) => {
                  payloadData.append('userVerification', file);
              });

              proofImg.forEach((file, index) => {
                  payloadData.append('companyVerification', file);
              });

              ownerIdImg.forEach((file, index) => {
                  payloadData.append('bankingVerification', file);
              });
              await AuthService.verifyStore(payloadData);
              break;
            }
            case 7: {

              const molliePayload: MollieLinkSubscriptionDto = {
                subscriptionId: storeFormData.subscriptionPlan?.id!,
                redirectBaseUrl: "https://www.yaldo.be/"
              }
              const result = await StoreService.linkSubscriptionPlan(molliePayload)
              if(result){
                //registration successful
                localStorage.removeItem("tempUserStoreData");
                createToast(result.message, ToastMessageType.Success);
              }
              break;
            }
            case 8: {
              //<Navigate to={"/dashboard"} />
              break;
            }
            default: {
              //console.log(storeFormData)

              //const { idImg, proofImg, ownerIdImg } = storeFormData;
              //const verificationData = { idImg, proofImg, ownerIdImg }
              //const res = await storeClient.post('/verify', verificationData)
              //console.log(res.data)
    
              //console.log(await AuthService.storeSignUp(storeFormData));
               /* addToast("Please fill in the needed fields", {
                appearance: "error",
              });
              showValidationErrorsRef.current(true);  */
            }
          }
        }
        else{
          createToast("Alle velden moeten ingevuld zijn", ToastMessageType.Error);
          console.log("forms are invalid");
          //<Navigate to={"/"} />
        }
      };
      
      
    
      const handlePreviousStep = () => {
        const prevStep = step - 1;
        setStep(prevStep);
        navigate(`/signup/step${prevStep}`);
      };

      const handleUserFormDataUpdate = async (data: any) => {
        switch (step) {
          case 1:
            setUserFormData((prevData) => ({
              ...prevData,
              preferedEmotions: data.preferedEmotions,
            }));
            break;
          case 2:
            setUserFormData((prevData) => ({
              ...prevData,
              firstName: data.firstName,
              lastName: data.lastName,
              phone: data.phone,
              dateOfBirth: new Date(data.dateOfBirth).toLocaleDateString(),
              email: data.email,
              password: data.password,
              address: data.address,
              lat: data.lat,
              lon: data.lon,
              country: data.country,
            }));
            break;
          case 3:
            setUserFormData((prevData) => ({
              ...prevData,
              profilePicture: data?.profilePicture,
            }));
            break;
          default:
            setUserFormData((prevData) => ({ ...prevData, ...data }));
        }
      };

      const handleStoreFormDataUpdate = async (data: StoreSignUpDto) => {
        let coordinates: { lat: any; lon: any; } | undefined;        
        if (data.address && data.address.trim() !== '') {
          console.log("I am here");
          coordinates = await getAddressCoordinates(data.address);

          console.log("lat, lon", coordinates?.lat, coordinates?.lon);
        }
        
        setStoreFormData((prevData) => ({
          ...prevData,
          yaldoName: data.yaldoName,
          companyName: data.companyName,
          country: data.country,
          address: data.address,
          iban: data.iban?.replace(/\s/g, ''),
          vat: data.vat?.replace(/\s/g, ''),
          bicc: data.bicc?.replace(/\s/g, ''),
          lon: coordinates?.lon,
          lat: coordinates?.lat,
          companyRepresentative: data?.companyRepresentative,
          ownerIdImg: data?.ownerIdImg,
          proofImg: data?.proofImg,
          idImg: data?.idImg,
          subscriptionPlan: data?.subscriptionPlan,
        }));
      };
    
      const handleUserAvailabilityFormDataUpdate = async (key: string, data: CreateDayOpeningHoursDto) => {
        setUserAvailabilityForm((prevState) => {
          const newAvailable = new Map(prevState.available);
          newAvailable.set(key, data); // Update the specific key with new data
          return {
            ...prevState,
            available: newAvailable, // Update the available field with the modified Map
          };
        });
      };

/*       const handleUserUnavailabilityFormDataUpdate = async (key: string, data: CreateClosedPeriodDto) => {
        setUserAvailabilityForm((prevState) => {
          const newUnavailable = new Map(prevState.unavailable);
          newUnavailable.set(key, data); // Update the specific key with new data
          return {
            ...prevState,
            unavailable: newUnavailable, // Update the available field with the modified Map
          };
        });
      }; */

      const renderStep = () => {
        switch (step) {
          case 1:
            return <Step1 onFormValidation={handleFormValidation} onUpdateFormData={handleUserFormDataUpdate}/>;
          case 2:
            return <Step2 onFormValidation={handleFormValidation} showValidationErrorsRef={showValidationErrorsRef} onUpdateFormData={handleUserFormDataUpdate} userData={userFormData}/>;
          case 3:
            return <Step3 onFormValidation={handleFormValidation} showValidationErrorsRef={showValidationErrorsRef} onUpdateFormData={handleUserFormDataUpdate}/>;
          case 4:
            return <Step4 onFormValidation={handleFormValidation} showValidationErrorsRef={showValidationErrorsRef} submitFormRef={submitFormRef} onUpdateFormData={handleStoreFormDataUpdate} userFormData={userFormData} storeFormData={storeFormData}/>;
          case 5: 
            return <Step5 title={["Select", "your availability"]} onFormValidation={handleFormValidation} onUpdateFormData={handleUserAvailabilityFormDataUpdate}/>;
          case 6:
            return <Step6 onFormValidation={handleFormValidation} showValidationErrorsRef={showValidationErrorsRef} onUpdateFormData={handleStoreFormDataUpdate} storeFormData={storeFormData}/>;
          case 7:
            return <Step7 onFormValidation={handleFormValidation} showValidationErrorsRef={showValidationErrorsRef} onUpdateFormData={handleStoreFormDataUpdate}/>;
          case 8: {
            return <Navigate to={"/"} />
          }
          default:
            return <Step1 onFormValidation={handleFormValidation} onUpdateFormData={handleUserFormDataUpdate}/>;
        }
      };

      const renderNavigationButtons = (child?: JSX.Element) => {
        return (
            <div
              className="parent-container"
              style={{
                bottom: '5%',
              }}
            >
              <div className="navigation-buttons w-full flex justify-center items-center">
                {step > 1 && (
                  
                  <button style={prevButton} className="previous-button" onClick={handlePreviousStep}>
                      <FontAwesomeIcon icon={faChevronLeft as IconProp} /> Previous
                  </button>
                )}
                {step === 5 && (
                  <button style={skipButton} onClick={async() => {
                    submitFormRef.current();
                    handleNextStep();
                  }}>
                    Skip this step
                  </button>
                )}
                {step < 8 && (
                  <button
                    style={nextButton}
                    className="next-button"
                    onClick={() => {
                      submitFormRef.current();
                      handleNextStep();
                    }}
                  >
                    {step === 7 ? "Finish" :  (<> Next <FontAwesomeIcon icon={faChevronRight as IconProp} /> </>)}
                  </button>
                )}
                </div>
            </div>


        );
      };

    return(
        <div className="flex bg-yaldoWhite w-full h-full">
            <div style={{ background: "linear-gradient(0deg, rgba(25,0,94,1) 0%, rgba(53,22,144,1) 80%)", borderRadius: "0 20px 20px 0", width: "20%", position: "relative" }}>
                <div style={{ position: "absolute", backgroundRepeat: "no-repeat", backgroundSize: "55%", width: "100%", height: "25vh", opacity: 0.05, marginLeft: "-10%", marginTop: "-10%", overflow: "hidden", zIndex: 2 }}></div>
                <div className="flex justify-center my-8 mt-20" style={{ marginBottom: "45%" }}>
                    <img className="w-40" src={yaldoIcon} alt="Yaldo" />
                </div>
                <Link to="/login" style={{fontFamily: "regular", color: "#B1B4D1", textAlign: "center", bottom: "10%", width:"100%", position: "absolute"}}>Already have an account? <br></br> <u style={{color: "white"}}>Sign In</u></Link> 
            </div>
            <div className="w-full h-full p-3 relative">
                <h1 style={{fontFamily: "regular", textAlign:"center", marginTop: "2%", marginBottom: "2%"}}>create your <b className="text-yaldoDarkBlue">yaldo experience creator</b> account</h1>
                <ProgresBar color='#19005E' height="10" minValue={1} maxValue={7} currentValue={step} />
                <Suspense fallback={<WaitingScreenScene secondary />}>{renderStep()}</Suspense>
                {renderNavigationButtons()}
                <ConfirmCancelDialog 
                onCancel={dontContinueSignup}
                onConfirm={() => setContinue(false)} //close dialog
                open={continueSignup} 
                message='Want to continue signup?'
                />
            </div>
        </div>

        
    )
}

const prevButton : CSS.Properties = {
  padding: "10px",
  borderRadius: "20px",
  color: Colors.red,
  fontWeight: 'bold',
  marginRight: '1.25rem',
  marginLeft: '1.25rem',
}

const skipButton : CSS.Properties = {
  color: '#19005e',
  fontWeight: 'bold',
  border: '2px solid #19005e',
  borderRadius: '20px',
  maxWidth: '197px',
  width: '100%',
  height: '40px',
  marginRight: '1.25rem',
  marginLeft: '1.25rem',
}


const nextButton : CSS.Properties = {
    padding: "10px 40px",
    borderRadius: "20px",
    maxHeight: '40px',
    backgroundColor: Colors.darkPurple,
    color: Colors.white,
    marginRight: '1.25rem',
    marginLeft: '1.25rem',
}

export default withNoAuth(Signup);