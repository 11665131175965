import React, { useEffect, useRef, useState } from "react";
import EventBookingDto from "../../dto/EventBooking.dto";
import EventCard from "../dashboard/EventCard";
import CSS from "csstype";
import { time } from "console";

function generateTimeSlots(): string[] {
  const timeSlots: string[] = [];

  // Loop through hours from 0 to 23
  for (let hour = 0; hour < 24; hour++) {
    const hourStr = hour.toString().padStart(2, "0");
    const timeSlot = `${hourStr}:00`; // Round to the nearest hour
    timeSlots.push(timeSlot);
  }

  return timeSlots;
}

const slotRowStyle2: CSS.Properties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const separator: CSS.Properties = {
  borderTop: "1px solid #19005E1A",
  marginTop: "50px",
  width: "100%",
  zIndex: 0,
};

const bookingCard: CSS.Properties = {
  height: "385px", // Adjusted to 385px
  width: "185px",
  borderRadius: "20px",
  opacity: 1,
  background: "#fff",
};

const RenderDayView = ({ currentDate, bookings }: { currentDate: Date, bookings: EventBookingDto[] }) => {
  const hourHeight = 385; // Adjusted to 385px
  //const cardWidth = 250; // 
  const cardMargin = 0.9 * 320;
  const bookingMap = new Map<string, EventBookingDto[]>();
  const temp: Record<string, JSX.Element> = {};
  const timeSlotsArray: string[] = generateTimeSlots();
  const firstYaldoRef = useRef<HTMLDivElement|null>(null);

  const currentDayOfWeek = currentDate.toLocaleDateString("default", {
    weekday: "long",
  });
  const currentDayBookings = bookings.filter((event) => {
    const eventDate = new Date(event.date);
    return (
      eventDate.toLocaleDateString("default", { weekday: "long" }) ===
        currentDayOfWeek &&
      eventDate.getFullYear() === currentDate.getFullYear() &&
      eventDate.getMonth() === currentDate.getMonth() &&
      eventDate.getDate() === currentDate.getDate()
    );
  });
  console.log(currentDayBookings)

  //const timeSlotsArray: string[] = generateTimeSlots(bookings);
  currentDayBookings.forEach((bookingSlot) => {
    const tod = new Date(bookingSlot.date);
    const formattedBookingSlot = tod.toLocaleTimeString([], {
      hour: "2-digit",
      /* minute: "2-digit", */
    }).substring(0,2);
    if (bookingMap.has(formattedBookingSlot)) {
      bookingMap.set(formattedBookingSlot, [...bookingMap.get(formattedBookingSlot)!, 
        bookingSlot,
      ]);
    } else {
      bookingMap.set(formattedBookingSlot, [bookingSlot]);
    }
  });

  const bookingSlotEntries = Array.from(bookingMap.entries());
  console.log(bookingSlotEntries);

  let innerloopItems = 0;
  const aTdWidth = document.getElementById("itemsTd")?.offsetWidth;
  const maxElements = Math.floor(aTdWidth! / cardMargin);

  console.log(`Calendar max width: ${aTdWidth}`);
  console.log(`Calendar can contain max ${maxElements} elements horizontally`);

  bookingSlotEntries.forEach(([key, value], idx) => {
    const previousItemsMargin = idx > 0 ? innerloopItems : 0;
    //console.log(key.substring(0,2))
    innerloopItems += value.length;
    temp[key.substring(0,2)] = (
      <div
        style={{
          marginLeft: `${cardMargin * previousItemsMargin}px`,
          display: "flex",
        }}
      >
        {value?.map((booking, bookingIdx) => {
          const bookingTime = new Date(booking.date);
          const minutes = bookingTime.getMinutes();
          const topCalculation = (hourHeight / 60) * minutes;
          return(
            <div key={bookingIdx} className="mr-8" style={{ position: 'relative', top: `${topCalculation}px`}}>
              <EventCard
                key={booking.id}
                id={booking.id}
                name={booking.eventVersion.name}
                coverPhoto={booking.eventVersion.images[0]}
                host={booking.host}
                status={booking.status}
                length={booking.eventVersion.length}
                bookingDate={new Date(booking.date)}
                location={booking.city + booking.address}
                bookerPhoto={booking.host.image}
                // intervalLength={60}
                // maxHeight={hourHeight}
              />
              {hourHeight && booking.eventVersion.length > 60 && (
              <div
                style={{
                  height: `${
                    ((booking.eventVersion.length - 60) * (hourHeight / 60) + 20) + 20
                  }px`,
                  width: '100%',
                  marginTop: '-20px',
                  left: 0,
                  right: 0,
                  position: 'absolute',
                  zIndex: 0,
                }}
                className="event_overflow"
              ></div>
              )}
            </div>
          )
        })}
      </div>
  )});

  //console.log(Array.from(emptyArray.entries()));

  useEffect(() => {
    if(firstYaldoRef.current){
      firstYaldoRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [currentDate])

  // Render the time slots and bookings
  return (
    <div style={{ maxHeight: "65vh", overflowY: "auto" }}>
      {/* Bovenste rij met de dag en datum */}
      <div
        style={{
          background: "#19005E",
          color: "white",
          padding: "10px",
          textAlign: "center",
          zIndex: 3,
          position: "sticky",
          top: 0,
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        {currentDayOfWeek} -{" "}
        {currentDate.toLocaleDateString("default", {
          month: "long",
          day: "numeric",
        })}
      </div>

      {/* Tabel voor weergave van uren en evenementen */}
      <table
        style={{
          width: "100%",
          height: "100%",
          tableLayout: "fixed",
          borderCollapse: "collapse",
        }}
      >
        <tbody>
          <tr>
            {currentDayBookings.length !== 0 ? 
            <>
              {/* Eerste kolom met uren */}
              <td
                style={{
                  width: "60px",
                  paddingRight: "10px",
                  verticalAlign: "top",
                  borderRight: "1px solid #19005E1A",
                }}
              >
                {/* {Array.from({ length: hoursInDay }, (_, hour) => (      
                ))} */}
                {timeSlotsArray?.map((ts, idx) => (
                  <div
                    key={`${ts}_${idx}`}
                    style={{
                      height: `${hourHeight}px`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      paddingRight: "5px",
                    }}
                  >
                    {ts}
                  </div>
                ))}
              </td>
              {/* Tweede kolom met boekingen */}
              <td id="itemsTd" className="pb-24 pt-32 " >
                {currentDate &&
                  timeSlotsArray.map((timeSlot, idx) => {
                    return (
                      <div
                      style={{ height: `${hourHeight}px` }}
                      id={timeSlot}
                      key={timeSlot}
                      ref={(temp[timeSlot.substring(0,2)]) ? firstYaldoRef : null}

                    >
                      <div style={slotRowStyle2}>
                        <span style={separator}></span>
                      </div>
                      {temp[timeSlot.substring(0,2)]}
                    </div>
                    );
                  })}
              </td>
            </>
            : 
            <div className="flex justify-center">
              <h1 className="p-4">Geen yaldo's</h1> 
            </div>
            }
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default RenderDayView;
