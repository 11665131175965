import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import CSS from 'csstype';
import { Colors } from './Colors';

interface ConfirmCancelDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
}

const ConfirmCancelDialog: React.FC<ConfirmCancelDialogProps> = ({
  open,
  onConfirm,
  onCancel,
  message,
}) => {
  const dialogRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (open && dialogRef.current && !dialogRef.current.contains(event.target as Node)) {
        onCancel();
      }
    };

    if (open) {
      // Add a click event listener to the document when the dialog is open
      document.addEventListener('click', handleClickOutside);
    } else {
      // Remove the event listener when the dialog is closed
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener('click', handleClickOutside);
    };
  }, [open, onCancel]);

  return (
    <>
    {open && (<div className={`dialog ${open ? 'open' : ''}`} ref={dialogRef}>
      <div className="dialog-content">
        <h3 className='pb-4'>{message}</h3>
        <button style={prevButton} className="previous-button"
                onClick={onCancel}>
             <>  <FontAwesomeIcon icon={faChevronLeft as IconProp} /> Cancel</>
        </button>
        <button style={nextButton}
                className="next-button"
                onClick={onConfirm}>
             <> Confirm <FontAwesomeIcon icon={faChevronRight as IconProp} /> </>
        </button>
      </div>
    </div>)}
    </>
  );
};

const prevButton : CSS.Properties = {
    padding: "10px",
    borderRadius: "20px",
    color: Colors.red,
    fontWeight: 'bold',
    marginRight: '1.25rem',
    marginLeft: '1.25rem',
  }
  
  const nextButton : CSS.Properties = {
      padding: "10px 40px",
      borderRadius: "20px",
      maxHeight: '40px',
      backgroundColor: Colors.darkPurple,
      color: Colors.white,
      marginRight: '1.25rem',
      marginLeft: '1.25rem',
  }

export default ConfirmCancelDialog;
