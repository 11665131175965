import React, { ReactElement } from "react";

export interface IProps {
  label: string;
}
export default function SubTitle({ label }: IProps): ReactElement {
  return (
    <div className="w-full border-b border-gray-400 text-gray-400 my-4">
      <strong>{label}</strong>
    </div>
  );
}
