import CSS from "csstype";
import { useState, useEffect } from "react";
import { UseFormSetValue, useForm } from "react-hook-form";
import CustomDropZone from "../../components/forms/CustomDropZone";
import addProfilePlaceholder from "../../assets/add_profile.png";
import { UserSignUpDto } from "../../dto/UserSignUp.dto";
import { CustomImage } from "../../dto/CustomImage";

interface Props {
  onFormValidation: (isValid: boolean) => void;
  showValidationErrorsRef: React.MutableRefObject<(value: boolean) => void>;
  onUpdateFormData: (data: any) => void;
}

export default function Step3({
  onFormValidation,
  showValidationErrorsRef,
  onUpdateFormData,
}: Props) {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm<UserSignUpDto>({ mode: "onChange" });
  const [images, setImages] = useState<any>();
  const watchFields = watch();

  const addImages = async (updatedImages: any[] | any) => {
    setImages(updatedImages[0]);

    const fileReadPromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve({ ...updatedImages[0], base64: reader.result });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(updatedImages[0]);
    })
    const myCustomImageFile = await fileReadPromise
    setValue!("profilePicture", (myCustomImageFile as CustomImage).base64);
  };

  const removeImage = (updatedImages: CustomImage) => {
    setImages(updatedImages);
  };

  function handleInputChange() {
    onUpdateFormData(watchFields);
  }

  useEffect(() => {
    handleInputChange();
  }, [JSON.stringify(watchFields)]);

  useEffect(() => {
    onFormValidation(images !== undefined);
    //onUpdateFormData({ images });
  }, [images]);

  return (
    <main style={{ marginTop: "5%" }}>
      <h1
        style={{
          fontFamily: "regular",
          textAlign: "center",
          marginTop: "2%",
          marginBottom: "5%",
        }}
      >
        Select your <b className="text-default">profile picture/logo</b>
      </h1>

      <div style={ContentWrapper}>
        <CustomDropZone<UserSignUpDto>
          uploadId="profilePicture"
          addImages={addImages}
          removeImage={removeImage}
          register={register}
          errors={errors}
          validation={{
            required: "Dit veld is verplicht",
          }}
          setValue={setValue as UseFormSetValue<UserSignUpDto>}
          placeHolderImage={addProfilePlaceholder}
          maxItems={1}
          rounded
          custom={true}
          initialImages={[]}
        />
      </div>
    </main>
  );
}

const ContentWrapper: CSS.Properties = {
  width: "50%",
  margin: "auto",
  paddingBottom: "5%",
  display: "flex",
  justifyContent: "center",
};
