import { useState, useEffect } from "react";
import CSS from 'csstype';
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { useMasterData } from "../context/MasterDataContext";
import {
  EventStatus,
  PriceType,
  EventLocation,
} from "../dto/Events.enum";
import { ReviewDto } from "../dto/Review.dto";
import WaitingScreenScene from "../scenes/waitingScreen/WaitingScreenScene";
import EventService from "../services/EventService";
import Input from "./forms/Input";
import Select, { ISelectOption } from "./forms/Select";
import TextArea from "./forms/TextArea";
import UpdateEvent from "../dto/UpdateEvent.dto";
import SubTitle from "./forms/SubTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import Event, { Inputs } from "../dto/Event.dto";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useUser } from "../context/UserContext";
import CustomDropZone from "./forms/CustomDropZone";
import verif1 from "../assets/verif1.png";
import { ToastMessageType, createToast } from "./Notifications";
import { EmotionDto } from "../dto/Emotion.dto";
import EmotionCardComponent from "./EmotionCardComponent";

//TODO update images dynamically
//we might want to only add an image or multiple images to the current list of images
//or might just want to remove 1 of the images of the current list of images

export const EventEdit = () => {
  const { id } = useParams<string>();
  //const [idNumber, setIdNumber] = useState<Event | null>(null);

  let idNumber: number = 0;

  if (!id) {
  } else {
    idNumber = parseInt(id);
  }
  const [event, setEvent] = useState<Event>();
  const [eventFormData, setEventFormData] = useState<Inputs>();
  const [formValid, setFormValid] = useState<boolean>(false);
  const [categories, setCategories] = useState<ISelectOption[]>([]);
  const [emotions, setEmotions] = useState<EmotionDto[] | null>(null);
  const defaultSelectedEmotions: number[] = [];
  const [selectedEmotions, setSelectedEmotions] = useState<number[]>(defaultSelectedEmotions);
  const [images, setImages] = useState<any[]>([]); 
  const [selectedImgs, setSelectedImgs] = useState<any[]>([]);
  const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);
  const [newImages, setNewImages] = useState<File[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { state: masterdata, dispatch: dispatchMasterData } = useMasterData();
  const { state: user } = useUser();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    reset,
  } = useForm<Inputs>({
    mode: "onBlur",
    reValidateMode: "onChange",
    shouldUnregister: false,
  });
  const watchFields = watch();

  const checkFormValidity = () => {
    let valid = true;
    
    // Controleer of vereiste velden zijn ingevuld, behalve "images"
    for (const [key, value] of Object.entries(watchFields)) {
      if ((value === null || value === "") && key !== "images") {
        valid = false;
        break; // Stop de loop als een vereist veld niet is ingevuld
      }
    }
    
    // Controleer of er ten minste één afbeelding is (nieuw of bestaand)
    if (images.length === 0 && newImages.length === 0) {
      valid = false;
    }
    
    setFormValid(valid);
  };
  

  const selectEmotion = (emotionId: number) => {
    if (selectedEmotions.includes(emotionId)) {
      setSelectedEmotions(selectedEmotions.filter(id => id !== emotionId));
    } else {
      setSelectedEmotions([...selectedEmotions, emotionId]);
    }
  };

  const handleInputData = (data: Inputs) => {
    console.log("handle input data",data);
    setEventFormData((prevState) => ({ ...prevState, ...data }));
  };

  useEffect(() => {
    handleInputData(watchFields);
    checkFormValidity();
    console.log(`Form is valid: ${formValid}`);
  }, [JSON.stringify(watchFields)]);

  useEffect(() => {
    async function getEvent() {
      try {
        const singleEvent = await EventService.getEventOnId(idNumber);
        setEvent(singleEvent);
        console.log("event", singleEvent);
        const imgs = singleEvent.images.map((image, idx) => ({
          id: `img_${idx}`,
          preview: image,
        }));
        setImages(imgs);
  
        // Initialize selected emotions with the emotions already present in event.emotions
        setSelectedEmotions(singleEvent?.emotions?.map(emotion => emotion.id) || []);
  
        reset({
          name: singleEvent?.name,
          subtitle: singleEvent?.subtitle,
          status: singleEvent?.status,
          description: singleEvent?.description,
          length: singleEvent?.length,
          bufferTime: singleEvent?.bufferTime,
          reservationBuffer: singleEvent?.reservationBuffer,
          category: singleEvent?.category?.id,
          minNbOfAttendees: singleEvent?.minNbOfAttendees,
          maxNbOfAttendees: singleEvent?.maxNbOfAttendees,
          emotionsIds: singleEvent?.emotions?.map(emotion => emotion.id) || [],
          priceType: singleEvent?.priceType as PriceType,
          fixedPrice: singleEvent?.fixedPrice || 0,
          pricePerAttendee: singleEvent?.pricePerAttendee || 0,
          location: singleEvent?.location as EventLocation,
          store: user?.stores?.id!,
        });
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    }
    if (idNumber) {
      getEvent();
      fetchAndSetCategoriesAndProducts();
      register("images", {
        validate: {
          required: (value: any[]) =>
            value.length > 0 || "Dit veld is verplicht",
        },
      });
    }
  
    const fetchAndSetEmotions = async () => {
      try {
        const _emotions = await EventService.getAllEmotions();
        setEmotions(_emotions);
      } catch (error) {
        console.error("Failed to fetch emotions:", error);
      }
    };
    fetchAndSetEmotions();
    async function fetchAndSetCategoriesAndProducts() {
      if (!masterdata.categories.length) {
        try {
          const categories = await EventService.getCategories();
          dispatchMasterData({ type: "setCategories", payload: categories });
          setCategories(
            categories.map((cat) => ({
              label: cat.name,
              value: cat.id.toString(),
            }))
          );
        } catch (e) {
          createToast("Er ging iets mis bij het ophalen van de masterdata.", ToastMessageType.Error);
        }
      } else {
        setCategories(
          masterdata.categories.map((cat) => ({
            label: cat.name,
            value: cat.id.toString(),
          }))
        );
      }
      // try {
      //   const products = await ProductService.getProductsOfUser();
      //   setProductsOfUser(
      //     products.map((product) => ({
      //       label: product.name,
      //       value: product.id?.toString(),
      //     }))
      //   );
      // } catch (e) {
      //   addToast(
      //     "Er ging iets mis bij het ophalen van alle producten van de huidige gebruiker.",
      //     {
      //       appearance: "error",
      //     }
      //   );
      // }
      setLoading(false);
    }
  }, [dispatchMasterData, idNumber, masterdata.categories, register, reset, user?.stores?.id]);

  const priceOptions: ISelectOption[] = [
    { value: "FREE", label: "Gratis" },
    { value: "FIXED_PRICE", label: "Vaste prijs" },
    { value: "PRICE_PER_ATTENDEE", label: "Prijs per deelnemer" },
    { value: "FIXED_ATTENDEE", label: "Vaste prijs en prijs per deelnemer" },
  ];

  const locationOptions: ISelectOption[] = [
    { value: "ONLY_IN_STORE", label: "In de winkel" },
    { value: "ONLY_AT_CLIENT", label: "Aan huis" },
    { value: "STORE_OR_CLIENT", label: "In de winkel of aan huis" },
  ];

  const statusOptions: ISelectOption[] = [
    { value: EventStatus.VISIBLE, label: "Zichtbaar" },
    { value: EventStatus.HIDDEN, label: "Verborgen" },
  ];

  const addImages = (newSelectedImages: File[]) => {
    console.log("Adding new images:", newSelectedImages);
    // Voeg de nieuwe afbeeldingen toe aan de huidige lijst van geselecteerde afbeeldingen
    setNewImages((prevImages) => {
      const updatedImages = [...prevImages, ...newSelectedImages];
      console.log("Updated new images list:", updatedImages);
      return updatedImages;
    });
  };
  

  const removeImage = (image: any) => {
    if (typeof image === 'string') {
      // Het is een URL van een bestaande afbeelding
      console.log("Marking existing image for removal:", image);
      setImagesToRemove(prev => [...prev, image]);
    } else if (image instanceof File) {
      // Het is een File object van een nieuwe afbeelding
      console.log("Removing new image:", image);
      setNewImages(prev => prev.filter(file => file !== image));
    }
    // Verwijder de afbeelding uit de algemene weergave (indien nodig)
    setImages(prev => prev.filter(img => img !== image));
  };
  
  


  const onSubmit = async (e: any) => {
    e.preventDefault();
    const newEvent: UpdateEvent = {
      id: idNumber,
      name: eventFormData?.name,
      subtitle: eventFormData?.subtitle,
      status: eventFormData?.status,
      description: eventFormData?.description,
      length: eventFormData?.length,
      emotionsIds: selectedEmotions,
      bufferTime: eventFormData!.bufferTime,
      reservationBuffer: eventFormData!.reservationBuffer,
      category: eventFormData?.category,
      minNbOfAttendees: eventFormData?.minNbOfAttendees,
      maxNbOfAttendees: eventFormData?.maxNbOfAttendees,
      priceType: eventFormData?.priceType as PriceType,
      fixedPrice: eventFormData?.fixedPrice || 0,
      pricePerAttendee: eventFormData?.pricePerAttendee || 0,
      // deposit: eventFormData?.deposit,
      location: eventFormData?.location as EventLocation,
      imagesToRemove: imagesToRemove,
      // compensationType: data.compensationType as CompensationType,
      // saleCompensation: data.saleCompensation,
      // bookingCompensationType: data.bookingCompensationType as BookingCompensationType,
      // bookingCompensationPoints: data.bookingCompensationPoints,
      // bookingCompensationFreeText: data.bookingCompensationFreeText,
    };

    console.log("newEvent", newEvent);
    if (images.length === 0 && newImages.length === 0) {
      createToast("Voeg minstens 1 afbeelding voor dit event", ToastMessageType.Warning);
      return;
    }
  
    if (!formValid) {
      createToast("Niet alle velden zijn gevuld", ToastMessageType.Error);
      return;
    }
  
    const formData = new FormData();
    formData.append('event', JSON.stringify(newEvent));
    const totalSize = newImages.reduce((acc, file) => acc + file.size, 0);
        console.log(`Totale grootte van afbeeldingen: ${totalSize}`);
        if (totalSize > 50000000) {
          throw new Error("Images are too large!");
        } 
    
        newImages.forEach((file, index) => {
          console.log(`Afbeelding ${index + 1}: ${file.name}, grootte: ${file.size}`);
          formData.append('images', file); 
        });
        // newImages.forEach(image => formData.append('images', image));
  
        console.log("Form data:", formData);
    try {
      const response = await EventService.updateEvent(formData, id!);
      if (response) {
        createToast("Event succesvol aangepast!", ToastMessageType.Success);
        navigate("/events");
      }
    } catch (error) {
      console.error("Er ging iets mis bij het bijwerken van het evenement:", error);
      createToast("Er ging iets mis bij het aanmaken van het evenement.", ToastMessageType.Error);
    }
  };

  return (
    <>
      {!event && loading ? (
        <WaitingScreenScene secondary />
      ) : (
        <div>
          <h1>Event aanpassen</h1>
          <div className="flex items-center justify-center">
            <div className="flex flex-col w-10/12">
              <form>
                <SubTitle label="Afbeeldingen" />
                <CustomDropZone<UpdateEvent>
                  uploadId="images"
                  addImages={addImages}
                  removeImage={removeImage}
                  initialImages={images}
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                  placeHolderImage={verif1}
                  {...register("images")}
                  maxItems={20}
                  custom={false}
                />
                <SubTitle label="Algemeen" />
                <Input
                  id="name"
                  label="Naam"
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                />
                <Input
                  id="subtitle"
                  label="Ondertitel"
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                />
                <Select
                  id="category"
                  label="Categorie"
                  example="Selecteer een category waarin je yaldo past..."
                  options={categories}
                  register={register}
                  errors={errors}
                  validation={{
                    valueAsNumber: true,
                    required: "Dit veld is verplicht",
                  }}
                />
                <TextArea
                  id="description"
                  label="Omschrijving"
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                />

                <div style={ContentWrapper}>
                <div>Event emotions*</div>
                {emotions && (
                  <div style={EmotionGrid}>
                    {emotions.map((emotion: EmotionDto) => (
                      <EmotionCardComponent
                        key={emotion.id}
                        emotion={{ ...emotion, id: emotion.id.toString() }}
                        isSelected={selectedEmotions.includes(emotion.id)}
                        onClick={() => selectEmotion(emotion.id)}
                      />
                    ))}
                  </div>
                )}

                </div>
                <div className="flex justify-between">
                  <div className="w-2/12">
                    <Input
                      id="length"
                      label="Duur in minuten"
                      type="number"
                      register={register}
                      errors={errors}
                      validation={{
                        required: "Dit veld is verplicht",
                        valueAsNumber: true,
                      }}
                    />
                  </div>
                  <div className="w-2/12">
                    <Input
                      id="bufferTime"
                      label="Buffertijd"
                      type="number"
                      register={register}
                      errors={errors}
                      validation={{
                        required: "Dit veld is verplicht",
                        valueAsNumber: true,
                      }}
                    />
                  </div>
                  <div className="w-2/12">
                    <Input
                      id="reservationBuffer"
                      label="Reserveringsbuffer"
                      type="number"
                      register={register}
                      errors={errors}
                      validation={{
                        required: "Dit veld is verplicht",
                        valueAsNumber: true,
                      }}
                    />
                  </div>
                  <div className="w-2/12">
                    <Input
                      id="minNbOfAttendees"
                      label="Minimum aantal deelnemers"
                      type="number"
                      register={register}
                      errors={errors}
                      validation={{
                        required: "Dit veld is verplicht",
                        valueAsNumber: true,
                        validate: {
                          lessThanMax: (value: string) =>
                            parseInt(value, 10) <
                              parseInt(
                                watch("maxNbOfAttendees")?.toString() ||
                                  "Infinity",
                                10
                              ) ||
                            "Minimaal aantal deelnemers moet kleiner zijn dan maximaal aantal deelnemers",
                        },
                      }}
                    />
                  </div>
                  <div className="w-2/12">
                    <Input
                      id="maxNbOfAttendees"
                      label="Maximaal aantal deelnemers"
                      type="number"
                      register={register}
                      errors={errors}
                      validation={{
                        required: "Dit veld is verplicht",
                        valueAsNumber: true,
                        validate: {
                          greaterThanMin: (value: string) =>
                            parseInt(value, 10) >
                              parseInt(
                                watch("minNbOfAttendees")?.toString() || "0",
                                10
                              ) ||
                            "Maximaal aantal deelnemers moet groter zijn dan minimum aantal deelnemers",
                        },
                      }}
                    />
                  </div>
                </div>
                <Select
                  id="status"
                  label="Status"
                  example="Selecteer een status van je yaldo..."
                  options={statusOptions}
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                />
                <SubTitle label="Prijs" />
                <Select
                  id="priceType"
                  label="Prijs"
                  example="Selecteer een prijstype van je yaldo..."
                  options={priceOptions}
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                />
                {watch("priceType") && (
                  <div className="flex justify-between">
                    {(watch("priceType") === "FIXED_PRICE" ||
                      watch("priceType") === "FIXED_ATTENDEE") && (
                      <div className="w-5/12">
                        <Input
                          type="number"
                          id="fixedPrice"
                          label="Vaste prijs"
                          register={register}
                          errors={errors}
                          setValue={setValue}
                          validation={{
                            required: true,
                            valueAsNumber: true,
                            shouldUnregister: true,
                          }}
                        />
                      </div>
                    )}
                    {(watch("priceType") === "PRICE_PER_ATTENDEE" ||
                      watch("priceType") === "FIXED_ATTENDEE") && (
                      <div className="w-5/12">
                        <Input
                          type="number"
                          id="pricePerAttendee"
                          label="Prijs per deelnemer"
                          register={register}
                          errors={errors}
                          setValue={setValue}
                          validation={{
                            required: true,
                            valueAsNumber: true,
                            shouldUnregister: true,
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
                <Select
                  id="location"
                  label="Locatie"
                  example="Selecteer een locatie waar je deze yaldo wilt aanbieden..."
                  options={locationOptions}
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                />

                <div
                  className="flex justify-between"
                  style={{ width: "400px" }}
                >
                  <button
                    className="bg-yaldoWhite text-yaldoDarkBlue hover:bg-yaldoDarkBlue hover:text-white border border-yaldoDarkBlue flex justify-between items-center"
                    style={{ padding: "10px 40px", borderRadius: 20 }}
                    type="submit"
                    onClick={onSubmit}
                  >
                    <FontAwesomeIcon
                      icon={faBookmark as IconProp}
                      className="mr-2"
                    />
                    <span>Opslaan</span>
                  </button>

                  <button
                    className="bg-yaldoWhite text-logout hover:bg-cancel hover:text-white border border-cancel"
                    style={{ padding: "10px 40px", borderRadius: 20 }}
                    type="button"
                    onClick={() => navigate("/events")}
                  >
                    Annuleren
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


const ContentWrapper : CSS.Properties = {
  width: "50%",
  /* height: 'calc(520px)', */
  overflowY: 'auto',
}

const EmotionGrid : CSS.Properties = {
  width: "100%",
  flexWrap: "wrap",
  marginBottom: "20px",
  padding: "6px",
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "row",
  gap: "20px",
}