import React, { useEffect, useState } from "react";
import Input from "./Input";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import "./inputStyles.module.css";
import { CreateDayOpeningHoursDto, CreateUserAvailability, DayOfWeek } from "../../dto/UserAvailability.dto";
import CustomTimePicker from "./CustomTimePicker";

type AvailibityInputs = {
  from: string;
  to: string;
  my_availibility: string;
};

interface IAvailability {
  id: string;
  onUpdateFormData: (key: string, data: CreateDayOpeningHoursDto) => void;
  onFormValidation: (isValid: boolean) => void;
  day?: number;
  start?: string;
  end?: string;
}

export default function AvailabilityInput(props: IAvailability) {
  const [selectedDay, setSelectedDay] = useState<string>("");
  const [startTime, setStartTime] = useState<string | undefined>(props.start || "09:00");
  const [endTime, setEndTime] = useState<string | undefined>(props.end || "12:00");
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    setError,
    control, clearErrors
  } = useForm<AvailibityInputs>({ mode: "onChange" });

  const onChangeStartTime = (aTime: string) => setStartTime(aTime);
  
  const onChangeEndTime = (aTime: string) => setEndTime(aTime);
  
  const handleDayChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDay(event.target.value);
    //console.log(`Day: ${event.target.value}`);
  };

  function checkFormValidity(openingHours: CreateDayOpeningHoursDto){
    const { openTime, closeTime } = openingHours;
    
    //console.log(`OpenTime: ${openTime}, closeTime: ${closeTime}`);
    const openTimestamp = new Date(`1970-01-01T${openTime}`);
    const closeTimestamp = new Date(`1970-01-01T${closeTime}`);
    //console.log(`OpenTime: ${openTimestamp.toLocaleTimeString()}, closeTime: ${closeTimestamp.toLocaleTimeString()}`);

    if ((!openTimestamp || !closeTimestamp) || (openTimestamp >= closeTimestamp)) {
        return false; // Close time should be after open time
    }
    //console.log(`Form ${props.id} is valid`);
    return true;
  }
  

  useEffect(() => {
    if (selectedDay && startTime && endTime) {
      const payload: CreateDayOpeningHoursDto = {
        dayOfWeek: Number.parseInt(selectedDay) as DayOfWeek,
        openTime: startTime,
        closeTime: endTime,
      }
      const validData = checkFormValidity(payload)
      if(validData){
        props.onFormValidation(true);
        props.onUpdateFormData(props.id, payload)
        clearErrors('my_availibility')
      } else {
        props.onFormValidation(false);
        setError('my_availibility', { type: 'onChange', message: "Eindtijd kan niet vroeger zijn dan starttijd" });
      }
    }
  }, [selectedDay, startTime, endTime]);

  useEffect(() => {
    if(props.day && props.start && props.end){
      const dayString = (document.getElementById(`day_${props.day}`) as HTMLOptionElement).value
      setSelectedDay(dayString)
    }
  }, [])


  return (
    <div className="flex flex-row justify-center w-[100%] p-4 mb-4">
      <div>
      <div className="relative mr-4">
        <select
          value={selectedDay}
          onChange={handleDayChange}
          style={{
            ...(selectedDay === "" ? { color: "gray" } : {}),
            ...{ width: "420px", height: "54px", borderRadius: "16px" },
          }}
          className={`${
            selectedDay !== "" ? "capitalize" : ""
          } block appearance-none w-full bg-white border 
          border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded 
          shadow leading-tight focus:outline-none focus:shadow-outline
          `}
        >
          {selectedDay === "" ? <option value="">e.g. Saturday</option> : <></>}
          {Object.entries(DayOfWeek)
            .filter((key) => isNaN(Number(key[0])))
            .map((key, idx) => (
              <option key={idx} id={`day_${idx}`} value={key[1]}>
                {key[0]}
              </option>
            ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
          <FontAwesomeIcon icon={faChevronRight} />
        </div>
      </div>
      {errors.my_availibility  && (
      <p className="text-red-600 text-sm mt-1 mb-0">
        {errors.my_availibility.message}
      </p>
      )}
      </div>

      <div className="flex flex-row items-center mb-6">
        <CustomTimePicker
          timeSlot={startTime}
          label="from"
          onChangeInput={onChangeStartTime}
        />
        <CustomTimePicker
          timeSlot={endTime}
          className="ml-4"
          label="to"
          onChangeInput={onChangeEndTime}
        />
      </div>
    </div>
  );
}
