import React from 'react';
import EventBookingDto from "../../dto/EventBooking.dto";

const RenderMonthView = ({ currentDate, bookings }: { currentDate: Date, bookings: EventBookingDto[] }) => {
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
  //console.log(daysInMonth)
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  let dayOfWeek = firstDayOfMonth.getDay(); // 0 (Sun) to 6 (Sat)
  dayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Zet zondag om naar 6 en verminder dag van de week met 1
  const weeksInMonth = Math.ceil((daysInMonth + dayOfWeek) / 7); // Aantal rijen in de tabel
  const monthDays: JSX.Element[] = [];
  const weekDays: JSX.Element[] = [];

  const today = new Date(); // Huidige datum

  // Vul de weekdagen in, beginnend met maandag
  const weekdays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const columnWidth = `${100 / 7}%`; // Even breedteverdeling voor 7 dagen
  for (let i = 0; i < 7; i++) {
    weekDays.push(
      <th
        key={i}
        style={{
          border: '1px solid black',
          padding: '10px',
          textAlign: 'center',
          minWidth: '50px',
          width: columnWidth, // Even breedte voor elke kolom
        }}
      >
        {weekdays[i]}
      </th>
    );
  }

  const daysInPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDate();
  let dayIndex = (1 - dayOfWeek)%7; // Begin op maandag

  const isCurrentMonth = currentDate.getMonth() === today.getMonth() && currentDate.getFullYear() === today.getFullYear();

  const cellSize = '50px';

  // Maak een mapping van datums naar bijbehorende boekingen
  const dateToBookingMap: { [date: string]: EventBookingDto[] } = {}; // Verander het type naar een array van boekingen
  for (const booking of bookings) {
    const bookingDate = new Date(booking.date);
    const bookingDay = bookingDate.getDate();
    const bookingMonth = bookingDate.getMonth();
    const bookingYear = bookingDate.getFullYear();

    // Controleer of de boeking binnen de huidige maand en jaar valt
    if (
      bookingMonth === currentDate.getMonth() &&
      bookingYear === currentDate.getFullYear() &&
      bookingDay >= 1 && bookingDay <= daysInMonth
    ) {
      const formattedDate = `${bookingYear}-${(bookingMonth + 1).toString().padStart(2, '0')}-${bookingDay.toString().padStart(2, '0')}`;
      
      // Voeg de boeking toe aan de array voor die datum
      if (!dateToBookingMap[formattedDate]) {
        dateToBookingMap[formattedDate] = [booking];
      } else {
        dateToBookingMap[formattedDate].push(booking);
      }
    }
  }

  for (let week = 0; week < weeksInMonth; week++) {
    const weekRow: JSX.Element[] = [];
  
    for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
      if (dayIndex < 1 || dayIndex > daysInMonth) {
        // Bepaal de datums van de vorige en volgende maand voor lege cellen
        let displayDate: number | string = '';
        if (dayIndex < 1) {
          displayDate = daysInPreviousMonth + dayIndex;
        } else {
          displayDate = dayIndex - daysInMonth;
        }
  
        weekRow.push(
          <td
            key={`${week}-${dayOfWeek}`}
            style={{
              border: '1px solid black',
              padding: '10px',
              textAlign: 'right', // Tekstuitlijning naar rechts
              verticalAlign: 'top', // Tekst bovenaan de cel
              backgroundColor: 'transparent', // Stel de achtergrondkleur in op transparant voor dagen van vorige en volgende maanden
              color: (dayIndex >= 1 && dayIndex <= daysInMonth) ? '#19005E' : '#B1A9C8', // Grijze tekst voor datums van vorige of volgende maand
              minWidth: cellSize,
              width: cellSize, // Even breedte voor elke kolom
              height: cellSize, // Maak elke cel een vierkant
            }}
          >
            {displayDate}
          </td>
        );
      } else {
        const formattedCellDate = `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${dayIndex.toString().padStart(2, '0')}`;
        const isCurrentDate = isCurrentMonth && dayIndex === today.getDate(); // Controleer of het de huidige datum is
  
        // Hier controleren we of het de huidige datum is om de achtergrondkleur in te stellen
        const cellBackgroundColor = isCurrentDate ? '#D1CCDF' : 'transparent';
  
        // Controleer of de huidige cel overeenkomt met een boekingsdatum
        const bookingsForDay = dateToBookingMap[formattedCellDate] || [];
  
        if (bookingsForDay.length > 0) {
          // Voeg een klasse toe voor styling
          const cellStyle: React.CSSProperties = {
            border: '1px solid black',
            padding: '10px',
            textAlign: 'right',
            verticalAlign: 'top',
            backgroundColor: cellBackgroundColor,
            color: '#19005E',
            minWidth: cellSize,
            width: cellSize,
          };
        
          const cellContent: JSX.Element[] = [];
        
          for (let i = 0; i < Math.min(bookingsForDay.length, 3); i++) {
            const booking = bookingsForDay[i];
            const zIndex = Math.max(3 - i, 1); // Bepaal de z-index op basis van de stapelvolgorde
            
            // Wrapper rond de afbeelding en de link met display: flex
            const imageWrapperStyle: React.CSSProperties = {
              marginBottom: '5px',
              display: 'flex',
              marginTop: "20%",
              // marginRight: 10,
              flexDirection: 'row',
              left: `${i * 60}px`,
              zIndex: zIndex,
            };
            
            cellContent.push(
              <div key={booking.id} style={imageWrapperStyle}>
                <a href={`/detail/${booking.id}`}>
                  <img
                    style={{ width: '50px', height: '50px', borderRadius: '15px', border: '1px solid white', objectFit: 'cover' }}
                    src={booking.host.image}
                    alt="User Profile"
                  />
                </a>
              </div>
            );
          }
        
          if (bookingsForDay.length > 3) {
            // Voeg een vierkante div toe voor het weergeven van het overschot
            cellContent.push(
              <div
                key="more"
                style={{
                  width: '50px',
                  height: '50px',
                  backgroundColor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  borderRadius: '15px',
                  display: 'flex', // Voeg display: flex toe
                  marginTop: "20%", // Voeg marginTop toe om de afbeeldingen te verplaatsen
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 1, // Zet het bovenop de andere afbeeldingen
                }}
              >
                +{bookingsForDay.length - 3}
              </div>
            );
          }
        
          weekRow.push(
            <td
              key={`${week}-${dayOfWeek}`}
              style={cellStyle}
            >
              {/* Wrapper rond de content van de boekingen */}
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                
                <br />
                {cellContent}
                {dayIndex}
              </div>
            </td>
          );
        
          // Voeg een klasse toe voor styling
          weekRow[weekRow.length - 1] = React.cloneElement(weekRow[weekRow.length - 1], { className: 'booking-cell' });
        } else {
          weekRow.push(
            <td
              key={`${week}-${dayOfWeek}`}
              style={{
                border: '1px solid black',
                padding: '10px',
                textAlign: 'right',
                verticalAlign: 'top',
                backgroundColor: isCurrentMonth && dayIndex === today.getDate() ? '#D1CCDF' : 'transparent',
                color: '#19005E',
                minWidth: cellSize,
                width: cellSize,
              }}
            >
              {dayIndex}
            </td>
          );
        }
      }
      dayIndex++;
    }

    monthDays.push(
      <tr key={week}>
        {weekRow}
      </tr>
    );
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <table className="dltrc">
        <tbody>
          <tr className="dlheader">
            {weekDays.map((day, index) => (
              <td
                className="dlheader"
                key={index}
                style={{
                  border: "1px solid #19005E", // Rand van elke koptekstcel
                  padding: "10px",
                  textAlign: "center",
                  minWidth: "50px",
                  width: columnWidth, // Even breedte voor elke kolom
                }}
              >
                {day.props.children}
              </td>
            ))}
          </tr>
          {monthDays.map((weekRow, index) => (
            <tr className="dlinfo" key={index}>
              {weekRow.props.children.map((dayCell: any, cellIndex: any) => (
                <td
                  className="dlinfo"
                  key={cellIndex}
                  style={{
                    padding: "10px 5px",
                    textAlign: "right",
                    verticalAlign: "top",
                    backgroundColor:
                      dayCell.props.style?.backgroundColor || "transparent",
                    color: dayCell.props.style?.color || "inherit",
                    minWidth: cellSize,
                    width: cellSize,
                    height: 125,
                  }}
                >
                  {dayCell.props.children}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RenderMonthView;
