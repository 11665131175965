// EmotionCardComponent.tsx

import React from 'react';
import CSS from 'csstype';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const EmotionCard : CSS.Properties =  {
  maxWidth: "160px",
  maxHeight: '160px',
  aspectRatio: "1",
  borderRadius: "15px",
  marginBottom: "20",
  display: "flex",
  position: "relative"
  // overflow: "hidden",
}
const EmotionImage  : CSS.Properties = {
  width: "100%",
  height: "100%",
  borderRadius: "15px",
  position: "relative",
  objectFit: 'cover'
}
const EmotionText : CSS.Properties =  {
  margin: 0,
  width: "100%",
  padding: "10px 10px",
  position: "absolute",
  fontFamily: "regular",
  fontSize: "16",
  color: "#fff",
  backgroundColor: "#000000a6",
  bottom: 0,
  borderBottomRightRadius: "15px",
  borderBottomLeftRadius: "15px",
}
const Selected : CSS.Properties =  {
  aspectRatio: "1",
  position: "absolute",
  top: 0,
  right: 0,
  padding: '20px',
}

interface Emotion {
  id: string;
  name: string;
  image: string;
}

interface EmotionCardProps {
  emotion: Emotion;
  isSelected: boolean;
  onClick: () => void;
}

const EmotionCardComponent: React.FC<EmotionCardProps> = ({emotion, isSelected, onClick}) => (
  <div style={EmotionCard} key={emotion.id} onClick={onClick}>
    <img src={emotion.image} alt={emotion.name} style={EmotionImage}/>
    {isSelected && (
      <div style={Selected}>
        <FontAwesomeIcon icon={faCheckCircle as IconProp} size="2x" style={{color: "#0DBE07"}}/>
      </div>
    )}
    <p style={EmotionText}>{emotion.name}</p>
  </div>
)

export default EmotionCardComponent;
