import React, { useState } from "react";
import CSS from "csstype";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

interface Props {
  plan: string;
  price: number;
  description: string;
  functions: string[];
  select?: (el: number) => void;
  selected?: number;
  id: number;
}

export default function SubscriptionCard(props: Props) {
  const isSelected = props.id === props.selected;

  return (
    <div
      onClick={() => props.select!(props.id)}
      className={`flex flex-col text-start cursor-pointer ${
        isSelected ? "selected" : ""
      }`}
      style={props.id === 2 ? SubscriptionCardStyle2 : SubscriptionCardStyle}
    >
      <div>
        <p className="mb-0 float-left">{props.plan}</p>
        <i>
          <FontAwesomeIcon
            style={isSelected ? CheckIconSelected : CheckIconStyle}
            size="2xl"
            className="float-right"
            icon={faCircleCheck}
          />
        </i>
      </div>
      <h2 className="mb-0">€ {props.price} /month</h2>
      <p className="text-[#C6BDDF] text-sm">{props.description}</p>
      <hr
        className={`border-t border-[${
          props.id === props.selected ? "#FFFFFF" : "#00000029"
        }] mx-4`}
      />
      <div className="flex flex-col mt-4 h-[100%] max-h-[160px] overflow-auto ">
        {props.functions.map((fun, idx) => (
          <p key={idx} className="p-0 m-2">
            <span className="mr-2">
              <FontAwesomeIcon icon={faCircleCheck} />
            </span>{" "}
            {fun}
          </p>
        ))}
      </div>
      <p
        className={`text-[${
          props.id === 2 ? "#FFFFFF" : "#19005e"
        }]  underline text-sm relative bottom-50 `}
      >
        Compare all subscriptions
      </p>
    </div>
  );
}

const SubscriptionCardStyle: CSS.Properties & {
  ":hover"?: CSS.Properties;
  ".selected"?: CSS.Properties;
} = {
  maxWidth: "320px",
  maxHeight: "440px",
  width: "100%",
  height: "100%",
  border: "none",
  padding: "1%",
  /*   borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "#19005E", */
  boxShadow: "0px 0px 6px #00000029",

  borderRadius: "20px",
  color: "#19005E",
  transition: "transform 0.4s ease",
};

const SubscriptionCardStyle2: CSS.Properties = {
  maxWidth: "320px",
  maxHeight: "440px",
  width: "100%",
  height: "100%",
  border: "none",
  padding: "1%",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: "#19005E",
  borderRadius: "20px",
  background:
    "transparent linear-gradient(36deg, #19005e 0%, #361692 100%) 0% 0% no-repeat padding-box",
  boxShadow: "0px 0px 6px #00000029",
  color: "#FFFFFF",
  transition: "transform 0.5s ease",
};

const CheckIconStyle: CSS.Properties = {
  color: "#C6BDDF",
  borderRadius: "50%",
};

const CheckIconSelected: CSS.Properties = {
  color: "#361692",
  backgroundColor: "#FFFFFF",
  borderRadius: "50%",
};
