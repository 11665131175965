export enum RepeatPattern {
    Weekly = 1,
    Monthly = 2,
    Yearly = 3,
}
export enum DayOfWeek {
    Sunday = 0, // Sunday corresponds to 0
    Monday = 1, // Monday corresponds to 1
    Tuesday = 2, // Tuesday corresponds to 2
    Wednesday = 3, // Wednesday corresponds to 3
    Thursday = 4, // Thursday corresponds to 4
    Friday = 5, // Friday corresponds to 5
    Saturday = 6, // Saturday corresponds to 6
}

export interface GetClosedPeriodDto {
    id: number;
    startDate: Date;
    endDate: Date;
    repeatPattern: RepeatPattern;
}

export interface GetDayOpeningHoursDto {
    id: number;
    dayOfWeek: DayOfWeek;
    // HH:mm
    openTime: string;
    closeTime: string;
}

export interface CreateClosedPeriodDto {
    startDate: Date;
    endDate: Date;
    repeatPattern: RepeatPattern;
}

export interface CreateDayOpeningHoursDto {
    dayOfWeek: DayOfWeek;
    // HH:mm
    openTime: string;
    // HH:mm
    closeTime: string;
}

export interface UserAvailabilityFormState {
    available: Map<string, CreateDayOpeningHoursDto>;
    unavailable: Map<string, CreateClosedPeriodDto>;
}

export interface CreateUserAvailability {
    available: CreateDayOpeningHoursDto[];
    unavailable: CreateClosedPeriodDto[];
}

export interface GetUserAvailability {
    id: number;
    available: CreateDayOpeningHoursDto[];
    unavailable: CreateClosedPeriodDto[];
}