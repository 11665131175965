import { useEffect, useState } from "react";
import CSS from "csstype";
import AvailabilityInput from "../../components/forms/AvailabilityInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  CreateDayOpeningHoursDto,
  CreateUserAvailability,
  GetUserAvailability,
} from "../../dto/UserAvailability.dto";
import UserService from "../../services/UserService";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ToastMessageType, createToast } from "../Notifications";

export default function EditAvailabilities() {
  const [availabilityInputs, setAvailabilityInputs] = useState<
    CreateDayOpeningHoursDto[]
  >([]);
  const [availabilities] = useState<Map<string, CreateDayOpeningHoursDto>>(
    new Map()
  );
  const [formIsValid, setFormValid] = useState(false);

  const handleAddClick = () => {
    setAvailabilityInputs([
      ...availabilityInputs,
      { dayOfWeek: 0, openTime: "09:00", closeTime: "12:00" },
    ]);
  };

  const onFormUpdate = (key: string, data: CreateDayOpeningHoursDto) => {
    availabilities.set(key, data);
  };

  const onFormValidation = (val: boolean) => {
    setFormValid(val);
  };

  async function submitUpdate(){
    if(formIsValid){
        const payload: CreateUserAvailability = {
            available: Array.from(availabilities.values()),
            unavailable: []
        }
        const response = await UserService.updateAvailability(payload)
        if(response){
            try{
                createToast("Availabilities succesvol aangepast!", ToastMessageType.Success);
            }
            catch(error){
                createToast("Er ging iets mis...", ToastMessageType.Error);
            }
        }
    } else {
        createToast("De velden zijn niet correct", ToastMessageType.Error);
    }
  }

  useEffect(() => {
    async function fetchAvailabilities() {
      const data: GetUserAvailability = await UserService.getAvailabilities();
      console.log(data);
      setAvailabilityInputs(data.available);
    }
    fetchAvailabilities();
  }, []);

  //useEffect(() => {}, [availabilityInputs])

  return (
    <>
      <div className="mb-16 mt-8">
        <h1
          style={{
            fontFamily: "regular",
            textAlign: "center",
            marginTop: "2%",
            marginBottom: "1.25rem",
          }}
        >
          <span className="font-thin">{"Update"}</span>{" "}
          <b className="text-default">{"your availabilities"}</b>
        </h1>
        <div className="w-fit ml-auto mr-auto ">
          <div style={avContainer} className="" id="availability_container">
            {availabilityInputs.map((availability, idx) => (
              <AvailabilityInput
                key={idx}
                id={`availability_${idx + 1}`}
                onFormValidation={onFormValidation}
                onUpdateFormData={onFormUpdate}
                start={availability.openTime}
                end={availability.closeTime}
                day={availability.dayOfWeek}
              />
            ))}
          </div>
          <div className="flex w-full justify-between">
            <div
              className="float-left ml-4 cursor-pointer mt-2"
              onClick={handleAddClick}
            >
              <FontAwesomeIcon icon={faPlus} size="xl" />
              <span> Add</span>
            </div>

            <div className="float-right cursor-pointer">
              <button
                className="bg-yaldoWhite text-yaldoDarkBlue hover:bg-yaldoDarkBlue hover:text-white border border-yaldoDarkBlue flex justify-between items-center"
                style={{ padding: "10px 40px", borderRadius: 20 }}
                type="button"
                onClick={submitUpdate}
              >
                <FontAwesomeIcon icon={faBookmark as IconProp} className="mr-2" />
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const avContainer: CSS.Properties = {
  maxHeight: "50vh",
  overflow: "auto",
};
