import React, { Suspense } from "react";
import { Route, Routes, NavLink } from "react-router-dom";
import WaitingScreenScene from "../../scenes/waitingScreen/WaitingScreenScene";
import { EditExperiences } from "./EditExperiences";
import { EditProfile } from "./EditProfile";
import { EditBusiness } from "./EditBusiness";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Colors } from "../Colors";
import EditAvailabilities from "./EditAvailabilities";

function EditSkeleton() {
  const getLinkClass = (isActive: boolean) => {
    return isActive
      ? "text-yaldoDarkpurple text-yaldoDarkBlue"
      : "text-yaldoInput";
  };
return (
<div style={{ margin: 20 }}>
  <h1 style={{ fontFamily: "bold", marginLeft: 0 }} className="text-yaldoDarkBlue">
    edit my profile
  </h1>
  <div style={{ display: "flex" }}>
    <div style={{
            width: "20%",
            height: "70vh",
            marginTop: "5%",
            marginLeft: "5%",
            display: "flex",
            flexDirection: "column",
            gap: 60,
            borderRight: "1px solid #979BB5",
            color: Colors.darkPurple,
            fontFamily: "semiBold",
            paddingRight: 20
          }}>
      {/* Navigation links */}
      <NavLink
        to="/dashboard/edit-account/experiences"
        className={({ isActive }) => getLinkClass(isActive)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
      <FontAwesomeIcon icon={faPenToSquare as IconProp} />
      <span style={{   display: "inline-block",
      width: "200px",
      marginRight: "20%", }}>
    edit my kind of experiences</span>
      <FontAwesomeIcon style={{position: "absolute", right: "10%" }} icon={faChevronRight as
        IconProp} />
      </NavLink>
      <NavLink
        to="/dashboard/edit-account/profile"
        className={({ isActive }) => getLinkClass(isActive)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
      <FontAwesomeIcon icon={faPenToSquare as IconProp} />
      <span style={{   display: "inline-block",
      width: "200px",
      marginRight: "20%", }}>
    edit my personal info</span>
      <FontAwesomeIcon style={{position: "absolute", right: "10%" }} icon={faChevronRight as
        IconProp} />
      </NavLink>
      <NavLink
        to="/dashboard/edit-account/business"
        className={({ isActive }) => getLinkClass(isActive)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
      <FontAwesomeIcon icon={faPenToSquare as IconProp} />
      <span style={{    display: "inline-block",
      width: "200px",
      marginRight: "20%",}}>
    edit my business info</span>
      <FontAwesomeIcon style={{position: "absolute", right: "10%" }} icon={faChevronRight as
        IconProp} />
      </NavLink>
      <NavLink
        to="/dashboard/edit-account/availabilities"
        className={({ isActive }) => getLinkClass(isActive)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
          marginBottom: "1rem",
          width: "100%",
        }}
      >
      <FontAwesomeIcon icon={faPenToSquare as IconProp} />
      <span style={{    display: "inline-block",
      width: "200px",
      marginRight: "20%",}}>
    edit my availabilities</span>
      <FontAwesomeIcon style={{position: "absolute", right: "10%" }} icon={faChevronRight as
        IconProp} />
      </NavLink>

    </div>

    <div className="flex-1 overflow-y-scroll">
      <Suspense fallback={<WaitingScreenScene secondary />}>
      <Routes>
        <Route path="experiences" element={<EditExperiences />} />
        <Route path="profile" element={<EditProfile />} />
        <Route path="business" element={<EditBusiness />} />
        <Route path="availabilities" element={<EditAvailabilities />} />
      </Routes>

      </Suspense>
    </div>
  </div>
</div>
);
}

export default EditSkeleton;