import { faBookmark } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState, useEffect } from "react";
import CSS from 'csstype';
import { useNavigate } from "react-router";
import { EmotionDto } from "../../dto/Emotion.dto";
import EventService from "../../services/EventService";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import EmotionCardComponent from "../EmotionCardComponent";

export const EditExperiences = () => {
    const navigate = useNavigate();
    const [emotions, setEmotions] = useState<EmotionDto[] | null>(null);
    const [selectedEmotions, setSelectedEmotions] = useState<number[]>([]);

    useEffect(() => {
        const fetchAndSetEmotions = async () => {
          const _emotions = await EventService.getAllEmotions();
          setEmotions(_emotions);
        };
        fetchAndSetEmotions();
      }, []);

      const onSubmit = () => {

      }

    const selectEmotion = (emotionId: number) => {
      console.log("CLICKED")
        if (selectedEmotions.includes(emotionId)) {
          setSelectedEmotions(selectedEmotions.filter((id) => id !== emotionId));
        } else {
          setSelectedEmotions([...selectedEmotions, emotionId]);
        }
      };
    return(
        <main>
            <h1 style={{ fontFamily: "regular", textAlign: "center", marginTop: "2%", marginBottom: "5%" }}>What <b style={{fontFamily: "semiBold"}}>kind of experiences</b> would you like to offer?</h1>
            <form onSubmit={onSubmit}>
                <div style={ContentWrapper}>
                    {emotions && (
                    <div style={EmotionGrid}>
                        {emotions.map((emotion) => (
                          <EmotionCardComponent
                          key={emotion.id}
                          emotion={{...emotion, id: emotion.id.toString()}} 
                          isSelected={selectedEmotions.includes(emotion.id)} 
                          onClick={() => selectEmotion(emotion.id)}
                        />
                        ))}

                    </div>
                    )}
                </div>
                <div className="w-full h-full flex items-end justify-center">
                    <div className="flex justify-between absolute" style={{width: "350px", margin: "auto", bottom: "10%"}}>
                        <button
                            className="bg-yaldoWhite text-yaldoDarkBlue hover:bg-yaldoDarkBlue hover:text-white border border-yaldoDarkBlue flex justify-between items-center"
                            style={{ padding: "10px 40px", borderRadius: 20 }}
                            type="submit"
                        >
                            <FontAwesomeIcon icon={faBookmark as IconProp} className="mr-2" />
                            <span>Opslaan</span>
                        </button>


                            <button
                            className="bg-yaldoWhite text-logout hover:bg-cancel hover:text-white border border-cancel"
                            style={{padding: "10px 40px", borderRadius: 20}}
                            type="button"
                            // onClick={() => history.replace("/events")}
                            onClick={() =>navigate("/events")}
                            >
                            Annuleren
                            </button>
                    </div>
                </div>
            </form>
        </main>
    )
}
const ContentWrapper : CSS.Properties = {
    width: "50%",
    margin: 'auto'
}

const EmotionGrid : CSS.Properties = {
    width: "100%",
    flexWrap: "wrap",
    padding: "6px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  }
  const EmotionCard : CSS.Properties =  {
    width: "24%",
    aspectRatio: "1",
    borderRadius: "15px",
    marginBottom: "20",
    display: "flex",
    position: "relative"
    // overflow: "hidden",
  }
  const EmotionImage  : CSS.Properties = {
    width: "100%",
    height: "100%",
    borderRadius: "15px",
    position: "relative",
  }

  const EmotionText : CSS.Properties =  {
    margin: 0,
    width: "100%",
    padding: "6px 10px",
    position: "absolute",
    fontFamily: "regular",
    fontSize: "16",
    color: "#fff",
    backgroundColor: "#000000a6",
    bottom: 0,
    borderBottomRightRadius: "15px",
    borderBottomLeftRadius: "15px",
  }

  const Selected : CSS.Properties =  {
    backgroundColor: "#0000007f",
    width: "100%",
    aspectRatio: "1",
    position: "absolute",
  }