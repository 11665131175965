import { useEffect, useState } from "react";
import SubscriptionCard from "../../components/SubscriptionCard";
import StoreService from "../../services/StoreService";
import { SubscriptionPlanDTO } from "../../dto/SubscriptionPlan.dto";

interface Props {
  onFormValidation: (isValid: boolean) => void;
  showValidationErrorsRef: React.MutableRefObject<(value: boolean) => void>;
  onUpdateFormData: (data: any) => Promise<void>;
}

export default function Step7({
  onFormValidation,
  showValidationErrorsRef,
  onUpdateFormData
}: Props) {
    const [selected, setSelected] = useState<number|null>(null);
    const [subscriptionsPlans, setSubscriptions] = useState<SubscriptionPlanDTO[]>([]);

    const chooseSubscription = (subscription:any) => {
        setSelected(subscription);
        onUpdateFormData({subscriptionPlan: subscriptionsPlans[subscription]});
        onFormValidation(true);
      };

      async function fetchSubscriptions(){
        const subscriptions = await StoreService.fetchSubscriptionPlans()
        setSubscriptions(subscriptions)
      }

      useEffect(() => {
        onFormValidation(false);
        fetchSubscriptions()
      }, [])
      
  return (
    <div className="mt-16 flex flex-col " style={{ height: "70%", width: "100%" }}>
    <div className="mt-8  grid-header justify-center flex  ">
        <h2 className="text-[24px] xl:text-[36px]"><span className="font-thin">Select</span> <b className="text-[24px] xl:text-[36px]">your yaldo subscription plan</b></h2>
    </div>
      <div className="flex flex-row p-20 justify-center justify-between py-8">
      {subscriptionsPlans?.map((offer, idx) => (
        <SubscriptionCard
          key={idx}
          id={idx}
          plan={offer.name}
          price={parseInt(offer.price)}
          description={offer.description}
          functions={offer.features}
          select={() => chooseSubscription(idx)}
          selected={selected!}
        />
      ))}
      </div>
    </div>
  );
}
