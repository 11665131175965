import LoginScene from "../src/scenes/login/LoginScene";
import WaitingScreenScene from "../src/scenes/waitingScreen/WaitingScreenScene";
import PageSkeleton from "./scenes/pageSkeleton/PageSkeleton";
import { UserProvider } from "./context/UserContext";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MasterDataProvider } from "./context/MasterDataContext";
import SignUp from "./scenes/signUp/SignUp";
import { Notifications } from "./components/Notifications";

const App: React.FC = () => {
  return (
      <UserProvider>
      <MasterDataProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/signup" element={<Navigate to="/signup/step1" />} />
            <Route path="/signup/*" element={<SignUp />} />
            <Route path="/login" element={<LoginScene />} />
            <Route path="/loading" element={<WaitingScreenScene />} />
            <Route path="/" element={<Navigate to="/dashboard" />} />
            <Route path="/*" element={<PageSkeleton />} />
          </Routes>
        </BrowserRouter>
        <Notifications />
      </MasterDataProvider>
    </UserProvider>
  );
}

export default App;