import CSS from 'csstype';

interface Props{
  color: string;
  minValue: number;
  maxValue: number;
  currentValue: number;
  height: string;
}
const ProgresBar = ({ color, currentValue, height, minValue, maxValue }: Props) => {

    const calculatePercentage = (value: number, min: number, max: number) => {
      const percentage = Math.floor(((value - min) / (max - min)) * 100);
      return Math.max(0, Math.min(100, percentage));
    };
  
    const percentage = calculatePercentage(currentValue, minValue, maxValue);
  

    if(currentValue >= 0 || currentValue <=100){
      const Wrapper : CSS.Properties = {
        width: '100%',
        display: "flex",
        justifyContent: "center"
      }
       
      const BackgroundBar : CSS.Properties = {
          height: `${height}px`,
          width: '50%',
          borderRadius: '40px',
          border: "1px solid #FFFFFF",
          color: "white",
          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
          position: "absolute",
        }
        
        const ActiveBar: CSS.Properties = {
          height: `${height}px`,
          width: `${percentage}%`,
          maxWidth: '100%',
          backgroundColor: color,
          borderRadius: '40px',
          marginTop: '-1px',
          position: 'relative',
        };
      
        const ShadowBar: CSS.Properties = {
          height: `${height}px`,
          width: `${percentage + 20}%`,
          maxWidth: '100%',
          backgroundColor: '#F3F2F7',
          borderRadius: '40px',
          marginTop: '-1px',
          position: 'absolute',
        };
  
        const progresstext : CSS.Properties = {
          padding: "10",
          margin: 'auto',
          color: '#19005E',
          width: 'fit-content',
          fontFamily: "semiBold",
          marginBottom: "10px"
        }
          
        return (
          <>
            <p style={progresstext}>{`${percentage}%`}</p>
            <div style={Wrapper}>
              <div style={BackgroundBar}>
                <div style={ShadowBar}></div>
                <div style={ActiveBar}></div>
              </div>
            </div>
          </>
        );
    } else {
      throw new Error("Progressbar must be between 0 and 100%")
    }
}
  
export default ProgresBar;