import axios from "axios";

const baseUrl = process.env.REACT_APP_BACKEND_URL || "http://localhost:3000";

// AUTH CLIENT
const authClient = axios.create({
  baseURL: `${baseUrl}/api/auth`,
});
authClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // Add bearer token
    if(bearerToken) config.headers['Authorization'] = "Bearer " + bearerToken;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
authClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  return Promise.reject(error.response.data);
});


// STORE CLIENT
let bearerToken: string | undefined;

const setBearerToken = (token: string) => {
  bearerToken = token;
  console.log("setBearerToken", token);
}

//Will be refactored later to use original storeClient
//hardedcoded for test purposes
const storeClient2 = axios.create({
  baseURL: `${baseUrl}/api/stores`,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

storeClient2.interceptors.request.use(
  function (config) {
    // Check if bearerToken is not undefined, if so, set the Authorization header
    if(bearerToken) config.headers['Authorization'] = "Bearer " + bearerToken;
    return config;
  },
  function (error) {
    console.log("store create ik faal hier", error);
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);

const storeClient = axios.create({
  baseURL: `${baseUrl}/api/stores`,
  headers: {
    Authorization: "Bearer " + (bearerToken || localStorage.getItem("userToken")),
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

storeClient.interceptors.request.use(
  function (config) {
    // Check if bearerToken is not undefined, if so, set the Authorization header
    if(bearerToken) {
      console.log(config.headers['Authorization'] = "Bearer " + bearerToken);
      config.headers['Authorization'] = "Bearer " + bearerToken;
    }
    return config;
  },
  function (error) {
    console.log("store create ik faal hier", error);
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);


storeClient.interceptors.response.use(undefined, function (error) {
  return Promise.reject(error.response.data);
});


// USERS CLIENT

const usersClient = axios.create({
  baseURL: `${baseUrl}/api/users`,
  headers: {
    Authorization: "Bearer " + (bearerToken || localStorage.getItem("userToken")),
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
usersClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const userToken = bearerToken || localStorage.getItem("userToken");
    if (userToken) {
      config.headers.Authorization = `Bearer ${userToken}`;
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
usersClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  if (error.response.status === 401) {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  }
  return Promise.reject(error.response.data);
});

// EVENT CLIENT

const eventClient = axios.create({
  baseURL: `${baseUrl}/api/events`,
  headers: {
    Authorization: localStorage.getItem("userToken")
      ? "Bearer " + localStorage.getItem("userToken")
      : null,
    accept: "application/json",
  },
});

eventClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
eventClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  if (error.response.status === 401) {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  }
  return Promise.reject(error.response.data);
});

// PRODUCT CLIENT

const productClient = axios.create({
  baseURL: `${baseUrl}/api/products`,
  headers: {
    Authorization: localStorage.getItem("userToken")
      ? "Bearer " + localStorage.getItem("userToken")
      : null,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});
productClient.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject({
      message:
        "Something went wrong when trying to contact the server. Please try again later!",
    });
  }
);
productClient.interceptors.response.use(undefined, function (error) {
  // Do something with response error
  //   console.log(error.response.data);
  //   console.log(error.response.status);
  //   console.log(error.response.headers);
  if (error.response.status === 401) {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  }
  return Promise.reject(error.response.data);
});

const vatValidationClient = async (vatNumber: string) => {
  try {
    const response = await axios.get(
      `https://vat.abstractapi.com/v1/validate/?api_key=af5bbf9a90f042159b35e5720095dd67&vat_number=${vatNumber}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// not mine still quota: af5bbf9a90f042159b35e5720095dd67
// mine not more quota: e242df5dfd8a426ca7b93e3a8e41dbd6

const ibanValidationClient =  async (ibanNumber: string) => {
  try{
    const response = await axios.get(
      `https://ibanvalidation.abstractapi.com/v1/?api_key=af5bbf9a90f042159b35e5720095dd67&iban=${ibanNumber}`
    );
    return response.data;
  } catch(error) {
    console.log(error);
    throw error;
  }
};

const getAddressCoordinates = async (address: string) => {
  try {
    const response = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${process.env.REACT_APP_GOOGLE_GEOCODE_API}`);

    if (response.data.status === 'OK') {
      const { lat, lng } = response.data.results[0].geometry.location;
      return { lat: (lat as Number).toFixed(2), lon: (lng as Number).toFixed(2) };
    } else if (response.data.status === 'REQUEST_DENIED') {
      console.error(`Request denied by the Google Maps API. Error message: ${response.data.error_message}`);
      throw new Error(`Request denied by the Google Maps API. Error message: ${response.data.error_message}`);
    } else {
      throw new Error(`Unexpected response status from the Google Maps API: ${response.data.status}`);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};





export { authClient, storeClient, storeClient2, usersClient, eventClient, productClient, vatValidationClient, ibanValidationClient, setBearerToken, getAddressCoordinates};
