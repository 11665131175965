import { usersClient } from "./HttpClients";
import User from "../dto/User.dto";
import UpdateUser from "../dto/UpdateUser.dto";
import { CreateUserAvailability } from "../dto/UserAvailability.dto";
import { JwtPayload } from "jwt-decode";

export default class UserService {
  public static async getUser(id?: number): Promise<User> {
    let response = await usersClient.get<User>(`/${id}`);
    let image = response.data;
    return image;
  }

  public static async updateUser(updateUser: UpdateUser): Promise<any> {

    return await usersClient.patch<void>("", updateUser);
  }

  public static async createAvailability(updateAvailability: CreateUserAvailability){
    try{
      let response = await usersClient.post('/availability', updateAvailability);
      return response.data;
    }
    catch(err){
      console.error(err);
    }
  }

  public static async updateAvailability(updateAvailability: CreateUserAvailability){
    try{
      let response = await usersClient.patch('/availability', updateAvailability);
      return response.data;
    }
    catch(err){
      console.error(err);
    }
  }

  public static async getAvailabilities(){
    try{
      let response = await usersClient.get('/availability');
      return response.data;
    }
    catch(err){
      console.error(err);
    }
  }
}
