import React, { ReactElement, useEffect, useRef, useState } from "react";
import { DeepMap, FieldError, FieldValues, UseFormReturn } from "react-hook-form";
import inputStyles from "./inputStyles.module.css";

export interface IProps {
  label: string;
  currentValue?: string | number;
  id: string;
  type?: string;
  onChange?: Function;
  register: Function;
  errors: DeepMap<FieldValues, FieldError>;
  showValidationErrors?: boolean;
  validation?: FieldValues;
  setValue?: Function;
  watch?: UseFormReturn<FieldValues>["watch"];
  phoneNumberExampleMessage?: string;
}

export default function Input({
  label,
  id,
  currentValue,
  type,
  register,
  errors,
  onChange,
  showValidationErrors,
  validation,
  setValue,
  watch,
  phoneNumberExampleMessage
}: IProps): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null);
  const [val, setVal] = useState<string|number|undefined>();

  useEffect(() => {
    if (inputRef.current && currentValue) {
      inputRef.current.value = currentValue.toString();
    }
    if (currentValue) {
      setVal(currentValue)
      if(setValue){
        setValue(id, currentValue);
      }
    }
  }, [currentValue/* currentValue, setValue, id */]);

  const customValidation = () => {
    const validate = {
      ...validation?.validate,
    };

    if (id === "minNbOfAttendees" && watch) {
      validate.lessThanMax = (value: string) => {
        return (
          parseInt(value, 10) <
            parseInt(watch("maxNbOfAttendees")?.toString() || "Infinity", 10) ||
          "Minimaal aantal deelnemers moet kleiner zijn dan maximaal aantal deelnemers"
        );
      };
    } else if (id === "maxNbOfAttendees" && watch) {
      validate.moreThanMin = (value: string) => {
        return (
          parseInt(value, 10) >
            parseInt(watch("minNbOfAttendees")?.toString() || "-Infinity", 10) ||
          "Maximaal aantal deelnemers moet groter zijn dan minimaal aantal deelnemers"
        );
      };
    }
    return validate;
  };

  return (
    <div className="flex flex-col mb-4 w-full">
      <label className="color-gray" style={{fontFamily: 'regular', fontSize: "1em"}} htmlFor={id}>
        {validation?.required ? (
          <div>
            {label}
            *
          </div>
        ) : (
          label
        )}
      </label>
      <input
        ref={inputRef}
        onChange={onChange}
        {...register(id, {
          ...validation,
          validate: customValidation(),
        })}
        onBlur={(e) => {
          if (id === "phone") {
            console.log("Phone input blurred");
            const value = e.target.value;
            const validationResult = validation?.validate
              ? validation.validate(value)
              : null;
            console.log("Validation result:", validationResult);
          }
        }}
        placeholder={label}
        className={`${inputStyles.input} ${errors[id] ? "error" : ""}`}
        type={type ? type : "text"}
        id={id}
        value={val|| undefined}
      />
    {(errors[id] || phoneNumberExampleMessage) && showValidationErrors && (
      <p className="text-red-600 text-sm mt-1 mb-0">
        {errors[id]?.message || phoneNumberExampleMessage}
      </p>
    )}
    </div>
  );
}
