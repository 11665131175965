import React, { ReactElement } from "react";
import { NavLink, useMatch } from "react-router-dom";
import classNames from "classnames";

interface Props {
  label: string;
  to: string;
}

export default function MenuItem({ label, to }: Props): ReactElement {
  const match = useMatch({ path: to, end: false });
  const isActive = !!match;

  return (
    <div
      className={classNames(
        "w-full font-semibold h-16 flex items-center justify-center text-white",
        {
          "bg-yaldoActiveNavColor text-white": isActive,
        }
      )}
    >
      <NavLink
        style={{ fontFamily: "semiBold" }}
        className={classNames({
          "": isActive,
        })}
        to={to}
      >
        {label}
      </NavLink>
    </div>
  );
}