import { useRef } from "react";
import { DeepMap, FieldError, FieldValues } from "react-hook-form";

export interface IProps {
    label?: string;
    id: string;
    register: Function;
    errors: DeepMap<FieldValues, FieldError>
    showValidationErrors?: boolean;
    validation?: FieldValues;

}

export const Checkbox = ({
    label,
    id,
    register,
    showValidationErrors,
    errors,
    validation,
}: IProps) => {
    const inputRef = useRef<HTMLInputElement>(null);

return (
    <div className="flex flex-col mb-4 w-full">
      <label
        className="color-gray"
        style={{ fontFamily: "regular", fontSize: "1em" }}
        htmlFor={id}
      >
        {validation?.required ? (
          <div>
            {label}
            *
          </div>
        ) : (
          label
        )}
      </label>
      <input
        ref={inputRef}
        {...register(id, {
          ...validation,
        })}
        placeholder={label}
        style={{
          borderColor: "#979bb5",
          borderWidth: 1,
          aspectRatio: 1,
          height: 25,
          fontFamily: "regular",
          color: "#818194",
        }}
        type="checkbox"
        id={id}
      />
      {errors[id] && showValidationErrors && (
        <p className="text-red-600 text-sm mt-1">{errors[id]?.message}</p>
      )}
    </div>
  );
}