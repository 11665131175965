import { ReactElement, useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import { Colors } from "../Colors";
import ContentWrapper from "./ContentWrapper";
import YaldoDoubleIcon from '../../assets/yaldo_double-icon_main_color.svg';
import EventCard from "./EventCard";
import Quote from "./Quote";
import EventBookingDto from "../../dto/EventBooking.dto";
import EventService from "../../services/EventService";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronRight, faClock, faMessage, faMoneyBill1 } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CSS from "csstype";

export default function Dashboard(): ReactElement {
    const { state } = useUser();
    const [bookings, setBookings] = useState<EventBookingDto[]>();

    const getBookings = async () => {
        const bookings = await EventService.getBookings();
        setBookings(bookings.sort((a: EventBookingDto, b: EventBookingDto) => new Date(a.date).getTime() - new Date(b.date).getTime()));
    }

    useEffect(() => {
        if (!bookings)
            getBookings();
    }, [bookings, state])

    const currentDateTime = new Date();
    let bookingDateTime = new Date();
    let timeUntilNextBooking = "";
    let nextBooking: EventBookingDto | null = null;

    if (bookings) {
        for (const booking of bookings) {
            bookingDateTime = new Date(booking.date);

            if (bookingDateTime > currentDateTime) {
                nextBooking = booking;
                break;
            }
        }
    }

    if (nextBooking) {
        const timeDifference = bookingDateTime.getTime() - currentDateTime.getTime();
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.ceil((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

        if (days > 0) {
            timeUntilNextBooking += `${days} days `;
        }
        if (hours > 0) {
            timeUntilNextBooking += `${hours}h `;
        }
        if (minutes > 0) {
            timeUntilNextBooking += `${minutes}min`;
        }

        console.log(`De eerstvolgende boeking vindt plaats over ${timeUntilNextBooking}.`);
    } else {
        console.log("Er zijn geen toekomstige boekingen.");
    }

    const mainStyle: CSS.Properties = {
        width: "95%",
        height: "85vh",
        marginTop: 0,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    };

    const h1Style: CSS.Properties = {
        fontFamily: "regular",
        margin: "2% 0 0"
    };

    const contentWrapperStyle: CSS.Properties = {
        display: "flex",
        justifyContent: "space-between",
        alignContent: "space-between",
        margin: "2% 0",
        width: "100%",
        height: 'fit-content',
    };

    const contentStyle: CSS.Properties = {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        padding: "0px 30px",
        width: "100%",
        margin: "10% 0",
        gap: "35px"
    };
    
    const innerContentStyle: CSS.Properties = {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", // aligneer elementen aan de linkerkant
        justifyContent: "center", // centreer de elementen verticaal
        width: "60%",
    };
    
    const h2Style: CSS.Properties = {
        color: Colors.darkPurple,
        fontFamily: 'semibold',
        margin: 0
    };

    return (
        <div style={{ display: "flex", flexDirection: 'row', justifyContent: "space-evenly" }}>
            <main style={mainStyle}>
                <h1 style={h1Style} className="text-yaldoDarkBlue"><b>hey {state?.firstName},</b> thanks for bringing joy to people's lives</h1>
                <div style={contentWrapperStyle}>
                    <ContentWrapper padding={0} width={32} color={Colors.lightPurple}>
                        <div style={contentStyle}>
                            <FontAwesomeIcon icon={faClock as IconProp} size={"5x"} color={Colors.darkPurple} />
                            <div style={innerContentStyle}>
                                <h2 style={h2Style}>{timeUntilNextBooking} till your next yaldo booking</h2>
                                {!timeUntilNextBooking ? <p style={{margin: 0, marginTop: '5%'}}>You currently don't have a booking in the future. <FontAwesomeIcon size={"1x"} icon={faChevronRight as IconProp}/></p>: null}
                            </div>
                        </div>
                    </ContentWrapper>
                    <ContentWrapper padding={0} width={32} color={Colors.lightPurple}>
                        <div style={contentStyle}>
                            <FontAwesomeIcon icon={faMessage as IconProp} size={"5x"} color={Colors.darkPurple} />
                            <div style={innerContentStyle}>
                                <h2 style={h2Style}>Messages are coming soon...</h2>
                                <p style={{margin: 0, marginTop: '5%'}}>Here you will see your messages. <FontAwesomeIcon size={"1x"} icon={faChevronRight as IconProp}/></p>
                            </div>
                        </div>
                    </ContentWrapper>
                    <ContentWrapper padding={0} width={32} color={Colors.lightPurple}>
                        <div style={contentStyle}>
                            <FontAwesomeIcon icon={faMoneyBill1 as IconProp} size={"5x"} color={Colors.darkPurple} />
                            <div style={innerContentStyle}>
                                <h2 style={h2Style}>Earning view is coming soon...</h2>
                                <p style={{margin: 0, marginTop: '5%'}}>Here you will see your earning view. <FontAwesomeIcon size={"1x"} icon={faChevronRight as IconProp}/></p>
                            </div>
                        </div>
                    </ContentWrapper>
                </div>
                <div style={{ width: "100%", marginBottom: "2%" }}>
                    <ContentWrapper padding={30} gap={35} width={100} color={Colors.lightPurple} contentTitle="upcoming yaldo's" linkName="view all" linkTo="calendar">
                        {bookings && bookings.length > 0 ? (
                            bookings
                                .slice(0, 5)
                                .map((booking: EventBookingDto) => (
                                    <EventCard
                                        name={booking.eventVersion.name}
                                        coverPhoto={booking.eventVersion.images[0]}
                                        length={booking.eventVersion.length}
                                        host={booking.host}
                                        status={booking.eventVersion.status}
                                        bookingDate={new Date(booking.date)}
                                        location={booking.address}
                                        bookerPhoto={booking.host.image}
                                        id={booking.id}
                                        key={booking.id}
                                        maxWidth={250}
                                    />
                                ))
                        ) : (
                            <p>You have currently no bookings.</p>
                        )}

                    </ContentWrapper>
                </div>
                <div style={contentWrapperStyle}>
                    <ContentWrapper padding={30} width={75} color={Colors.lightPurple} contentTitle="statistics" linkName="view all statistics" linkTo="stats">
                        <div style={contentStyle}>
                            <div style={innerContentStyle}>
                                <h2 style={h2Style}>yaldo booking statistics are coming soon...</h2>
                            </div>
                        </div>
                    </ContentWrapper>
                    <ContentWrapper padding={30} width={22} color={Colors.lightPurple}>
                        <Quote img={YaldoDoubleIcon}></Quote>
                    </ContentWrapper>
                </div>
            </main>
            {/* <aside style={{ width: "25%", marginTop: 20, display: "inline-flex", flexDirection: "column", flexWrap: "wrap", gap: 25 }}>
                <ContentWrapper padding={30} width={100} color={Colors.darkPurple} contentTitle="my team" linkName="view all" linkTo="team">
                    <p style={{ color: Colors.white }}>coming soon</p>
                </ContentWrapper>
                <ContentWrapper padding={30} width={100} color={Colors.darkPurple} contentTitle="yaldo news" linkName="view all" linkTo="team">
                    <p style={{ color: Colors.white }}>coming soon</p>
                </ContentWrapper>
            </aside> */}
        </div>
    )
}
