import { Link } from "react-router-dom";
import { Checkbox } from "./Checkbox";

interface IProps{
    register: any;
    errors: any;
    showValidationErrors: boolean;
}

export const TermsAndConditions = ({register, errors, showValidationErrors}: IProps) => {
    return(
    <div className="flex" style={{width: "100%"}}>
    <Checkbox id={"terms"} register={register} errors={errors} showValidationErrors={showValidationErrors}
        validation={{
        required: "Dit veld is verplicht",
        }} />
    <p className="ml-2 pt-[6px]" style={{minWidth: "90%"}}>Please accept our <Link to={{ pathname: "https://yaldo.io/privacy-policy" }} target="_blank" style={{ color: "blue", textDecoration: "underline" }}>terms and condition</Link>.</p>
    </div>
    )
}

export default TermsAndConditions;