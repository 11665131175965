import React, { ChangeEventHandler, useState } from 'react'
import CSS from 'csstype';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

interface Props{
  label: string;
  className?: string;
  timeSlot?: string;
  onChangeInput: (val:string) => void;
}

export default function CustomTimePicker({label, className, timeSlot, onChangeInput}: Props) {
    const [time, setTime] = useState(timeSlot||'00:00');

    const isValidTime = (input:any) => {
        const timeFormat = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/;
        return timeFormat.test(input);
      }

    const handleIncrease = () => {
        let [hours, minutes] = time.split(':').map(Number);
        hours++;
        if (hours > 23) {
          hours = 0;
        }
        const aTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        setTime(aTime);
        onChangeInput(aTime);
      };
    
      const handleDecrease = () => {
        let [hours, minutes] = time.split(':').map(Number);
        hours--;
        if (hours < 0) {
          hours = 23;
        }
        const aTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
        setTime(aTime);
        onChangeInput(aTime);
      }
  
      const handleChange = (e:any) => {
        if (isValidTime(e.target.value)) {
          setTime(e.target.value);
          console.log(e.target.value)
        }
    }
      
  
    return (
      <div className={`flex flex-row items-center ${className}`}>
      <label className="mr-2 pl-4 capitalize" id="start-time-input">
        {label}{" "}
      </label>
      <div style={CustomPickerWrapper} className="time-picker flex flex-row">
        <button className="hoverable-button" style={ButtonStyle} onClick={handleDecrease}><FontAwesomeIcon icon={faMinus} /></button>
        <input disabled={true} style={PickerStyle} type="text" value={time} onChange={handleChange}/>
        <button className="hoverable-button" style={ButtonStyle} onClick={handleIncrease}><FontAwesomeIcon icon={faPlus} /></button>
      </div>
    </div>

    );
}

const PickerStyle : CSS.Properties = {
    width: '80px',
    textAlign: 'center',
}

const ButtonStyle : CSS.Properties = {
    background: '#979BB5',
    boxShadow: '0px 30px 30px #AAAAAA26',
    border: '1px  solid #979BB5',
    borderRadius: '16px',
    width: '54px',
    height: '54px',
    transition: 'transform 0.3s ease',
}

const CustomPickerWrapper : CSS.Properties = {
    boxShadow: '0px 30px 30px #AAAAAA26',
    border: '1px  solid #979BB5',
    borderRadius: '17px',
}
