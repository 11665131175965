import CSS from "csstype";
import { useEffect, useState } from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import Input from "../../components/forms/Input";
import { useForm } from "react-hook-form";
import CountrySelector from "../../components/forms/CountrySelector";
import StoreSignUpDto from "../../dto/StoreSignUp.dto";
import User from "../../dto/User.dto";
import TermsAndConditions from "../../components/forms/TermsAndConidtion";
import StreetInput from "../../components/forms/StreetInput";
import { ICountryData } from "./Step2";
import { UserSignUpDto } from "../../dto/UserSignUp.dto";

type BusinessInfo = {
  yaldoName: string;
  companyName: string;
  country: string;
  address: string;
  iban: string;
  vat: string;
  bicc: string;
  companyRepresentative: string;
  lat: string;
  lon: string;
  terms: boolean;
  owner?: User;
};

interface Props {
  onFormValidation: (isValid: boolean) => void;
  showValidationErrorsRef: React.MutableRefObject<(value: boolean) => void>;
  submitFormRef: React.MutableRefObject<() => void>;
  onUpdateFormData: (data: any) => void;
  userFormData: UserSignUpDto;
  storeFormData: StoreSignUpDto;
}

export default function Step4({
  onFormValidation,
  showValidationErrorsRef,
  submitFormRef,
  onUpdateFormData,
  userFormData, storeFormData
}: Props) {
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    setError, reset,
    control, clearErrors
  } = useForm<BusinessInfo>({ mode: "onChange" });
  const [biccValue, setBiccValue] = useState("");

  const handleBiccChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithoutSpaces = e.target.value.replace(/\s/g, "");
    setBiccValue(valueWithoutSpaces);
    setValue("bicc", valueWithoutSpaces);
  };

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [formValid, setValid] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<ICountryData>();

  const watchFields = watch();
  const ref = usePlacesWidget({
    apiKey: "AIzaSyA2ak0Saovn-p2GwcAOYirDuEdvqBnGlDU",
    onPlaceSelected: (place) => {
      if (place.address_components) {
        const city = place.address_components.find((component) =>
          component.types.includes("locality")
        );
        const postalCode = place.address_components.find((component) =>
          component.types.includes("postal_code")
        );
        const street = place.address_components.find((component) =>
          component.types.includes("route")
        );
        const streetNumber = place.address_components.find((component) =>
          component.types.includes("street_number")
        );
        const country = place.address_components.find((component) =>
          component.types.includes("country")
        );

        setValue(
          "address",
          street && streetNumber && city && postalCode && country
            ? `${street.long_name} ${streetNumber.long_name}, ${postalCode.long_name} ${city.long_name}, ${country.long_name}`
            : ""
        );
      }
    },
  });

  const checkFormValidity = () => {
    let valid = true;

    for (const [key, value] of Object.entries(watchFields)) {
      if (value === "" || (key === "terms" && value === false)) {
        setError(key as keyof BusinessInfo, {
          type: "required",
          message: "Dit veld is verplicht",
        });
        valid = false;
      }
    }
    onFormValidation(valid);
    if(valid) setValid(true)
  };

  const onCountryChange = (country: ICountryData) => {
    setSelectedCountryCode(country);
    userFormData!.country = country.countryCode;
    userFormData!.countryName = country.fullName;
    setValue("country", country.countryCode);
  };

  function handleInputChange() {
    onUpdateFormData(watchFields);
  }

  useEffect(() => {
    showValidationErrorsRef.current = setShowValidationErrors;
    setShowValidationErrors(true);
    
    if (Object.keys(watchFields).length !== 0) {
      (async () => {
        await checkFormValidity();
        handleInputChange();
      })();
    }
  }, [JSON.stringify(watchFields)]);

  useEffect(() => {
    if(storeFormData){
      const previousInput = {
        yaldoName: storeFormData?.yaldoName,
        companyName: storeFormData?.companyName,
        country: storeFormData?.country,
        address: storeFormData?.address,
        iban: storeFormData?.iban,
        vat: storeFormData?.vat,
        bicc: storeFormData?.bicc,
        companyRepresentative: storeFormData?.companyRepresentative || ''
    }
    setSelectedCountryCode({ countryCode: userFormData?.country!, fullName: userFormData?.countryName! } as ICountryData);
    const addressInput = document.getElementById('address') as HTMLInputElement;
    addressInput.value = storeFormData?.address;
    reset(previousInput);

    //we gaan ervan uit dat alles al ingevuld is
    if(storeFormData.bicc && storeFormData.iban){
      setValue('terms', true);
    }

    //om error op te kuisen bij automatische filling
    setValue('country', userFormData?.country);
    clearErrors('country');
    }
  }, []);

  return (
    <main style={{ marginTop: "5%" }}>
      <h1
        style={{
          fontFamily: "regular",
          textAlign: "center",
          marginTop: "2%",
          marginBottom: "1.25rem",
        }}
      >
        Fill in your <b className="text-default">business</b> information
      </h1>

      <div style={ContentWrapper}>
        <div className="w-full" style={RowInput}>
          <div style={WidthInputWrapper}>
            <Input
              label={
                "yaldo name (This name will be displayed with your yaldo's)"
              }
              id={"yaldoName"}
              register={register}
              errors={errors}
              showValidationErrors={showValidationErrors}
              validation={{
                required: "Dit veld is verplicht",
              }}
            />
            <Input
              label={"Company name"}
              id={"companyName"}
              register={register}
              errors={errors}
              showValidationErrors={showValidationErrors}
              validation={{
                required: "Dit veld is verplicht",
              }}
            />
          </div>
          <div style={WidthInputWrapper}>
            <Input
              label={"VAT"}
              id={"vat"}
              register={register}
              errors={errors}
              showValidationErrors={showValidationErrors}
              validation={{
                required: "Dit veld is verplicht",
              }}
            />
            <Input
              label={`Company legal representative name`}
              id={"companyRepresentative"}
              register={register}
              errors={errors}
              showValidationErrors={showValidationErrors}
              validation={{
                required: "Dit veld is verplicht",
              }}
            />
          </div>
        </div>

        <div className="w-full" style={RowInput}>
          <div style={WidthInputWrapper}>
            <CountrySelector
              key={1}
              label={"Country"}
              id={"country"}
              register={register}
              errors={errors}
              showValidationErrors={showValidationErrors}
              onCountryChange={onCountryChange}
              validation={{
                required: "Dit veld is verplicht",
              }}
              clearErrors={clearErrors}
              countryCode={selectedCountryCode?.countryCode || userFormData?.country} 
              countryName={selectedCountryCode?.fullName || userFormData?.countryName}
            />
          </div>
          <div style={WidthInputWrapper}>
            <StreetInput
              control={control}
              id="address"
              label="Street, Number, Postal Code, City"
              register={register}
              errors={errors}
              selectedCountryCode={selectedCountryCode?.countryCode}
              validation={{
                required: "Dit veld is verplicht",
              }}
            />
          </div>
        </div>

        <div className="w-full" style={RowInput}>
          <div style={WidthInputWrapper}>
            <Input
              label={"BIC"}
              id={"bicc"}
              register={register}
              errors={errors}
              onChange={handleBiccChange}
              showValidationErrors={showValidationErrors}
              validation={{
                required: "Dit veld is verplicht",
              }}
            />
          </div>
          <div style={WidthInputWrapper}>
            <Input
              label={"IBAN"}
              id={"iban"}
              register={register}
              errors={errors}
              showValidationErrors={showValidationErrors}
              validation={{
                required: "Dit veld is verplicht",
              }}
            />
          </div>
        </div>
        <TermsAndConditions
          register={register}
          errors={errors}
          showValidationErrors={showValidationErrors}
        />
      </div>
    </main>
  );
}

const ContentWrapper: CSS.Properties = {
  width: "60%",
  margin: "auto",
};

const WidthInputWrapper: CSS.Properties = {
width: "50%",
display: "flex",
flexDirection: "column",
};

const RowInput: CSS.Properties = {
display: "flex ",
justifyContent: "space-between",
flexGrow: 1,
};

// EVERYTHING FOR VALIDATION OF VATDATA en ibandata

// const [vatValidationObject, setVatValidationObject] = useState<any | null>(null);
// const [vatData, setVatData] = useState<VatValidationDto | null>();
// const [ibanData, setIbanData] = useState<IbanValidationDto | null>();

// const fillInformationBasedOnVat = (vatData: VatValidationDto) => {
//   console.log("fill in");
//   if (vatData && vatData.valid === true) {
//     setValue("country", vatData?.country.code);
//     setValue("companyName", vatData?.company.name);
//     setValue("address", vatData?.company.address);
//   }
// };

// const vatDataFetch = async (vatNumber: string) => {
//   try {
//     const response = await ValidationService.getVatNumber(vatNumber);
//     setVatData(response);
//   } catch (e) {
//     throw new Error("Not found or unable to fetch");
//   }
// };

// const ibanDataFetch = async (ibanNumber: string) => {
//   try {
//     const response = await ValidationService.getIbanNumber(ibanNumber);
//     setIbanData(response);
//   } catch (e) {
//     throw new Error("Not found or unable to fetch");
//   }
// }

// if there is a subscription on the library see httpClient.ts
// you can still add ibanData?.is_valid === true
// api needed and limited usage!!!
// if(vatData?.valid) {
//   const newBusiness: StoreSignUpDto = {
//     name: data.name,
//     country: data.country,
//     city: data.city,
//     address: vatData?.company.address,
//     iban: data.iban,
//     vat: data.vat,
//     bicc: data.bic,
//     companyRepresentative: data.companyRepresentative,
//     lat: data.lat,
//     lon: data.lon,
//   }
//   try{
//     await AuthService.storeSignUp(newBusiness);
//   }
//   catch(err) {
//     addToast("Er ging iets mis bij het ophalen van de masterdata.", {
//     appearance: "error",
//     });
//   }
// } else if(ibanData?.is_valid === false || !ibanData){
//   addToast("Je iban bestaat niet of kon niet gevalideerd worden", {
//     appearance: "warning",
//     });
// }
// else if (!vatData?.valid) {
//   addToast("Je vat bestaat niet of kon niet gevalideerd worden", {
//     appearance: "warning",
//     });
// }

// const debouncedFetchVatData = useCallback(
//   debounce((vatNumber: string) => {
//     vatDataFetch(vatNumber);
//   }, 500),
//   []
// );

// const debouncedFetchIbanData = useCallback(
//   debounce((ibanNumber: string) => {
//     ibanDataFetch(ibanNumber);
//   }, 500),
//   []
// )

// NEEDS TO BE IN USEEFFECT
// if (vatNumber && vatNumber.length > 11) {
//   if (!vatData || vatData.valid === false) {
//     debouncedFetchVatData(vatNumber);
//   }
// }

// if (ibanNumber && ibanNumber.length > 14) {
//   if (!ibanData || ibanData?.is_valid === false) {
//     debouncedFetchIbanData(ibanNumber);
//   }
// }
// if (vatData) {
//   fillInformationBasedOnVat(vatData);
// }
