import React from 'react';
import toast, { Toaster } from 'react-hot-toast';

enum ToastMessageType {
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

const toastColors = {
  [ToastMessageType.Success]: '#5FFF5A',
  [ToastMessageType.Error]: '#FF5A5A',
  [ToastMessageType.Warning]: '#ffa000',
};

const createToast = (message: string, type: ToastMessageType, moreInfo?: boolean) => {
  toast.custom((t) => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        top: '5%',
        right: '2%',
        transform: `translateY(${t.height}px)`,
        minWidth: '260px',
        backgroundColor: '#fff',
        transition: 'all 0.5s ease-out',
        opacity: t.visible ? 1 : 0,
        borderRadius: '10px',
        border: `1px solid ${toastColors[type]}`,
        borderLeft: `8px solid ${toastColors[type]}`,
        padding: '15px',
        zIndex: 9999,
        boxSizing: 'border-box',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <span style={{ flex: 1, marginRight: '10px' }}>{message}</span>
        <div
          style={{
            cursor: 'pointer',
          }}
          onClick={() => toast.dismiss(t.id)}
        >
          ✕
        </div>
      </div>
      {moreInfo && (
        <a
          href="#"
          style={{
            marginTop: '8px',
            color: '#0066ff',
            textDecoration: 'underline',
            fontSize: '14px',
            fontWeight: 'bold',
            alignSelf: 'start',
          }}
        >
          more info
        </a>
      )}
    </div>
  ));
};

const Notifications = () => {
  return <Toaster position="top-right" reverseOrder={false} />;
};

export { Notifications, createToast, ToastMessageType };
