import React, { useEffect, useState } from "react";
import CSS from "csstype";
import AvailabilityInput from "../../components/forms/AvailabilityInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { CreateDayOpeningHoursDto } from "../../dto/UserAvailability.dto";

interface Props{
  onFormValidation: (isValid: boolean) => void;
  onUpdateFormData: (key: string, data: CreateDayOpeningHoursDto) => void;
  title: string[];
}

export default function Step5({onFormValidation, onUpdateFormData, title}: Props) {
  const [availabilityInputs, setAvailabilityInputs] = useState([
    { id: 'test' },
    { id: 'test2' },
    { id: 'test3' },
  ]);

  const handleAddClick = () => {
    const newId = `test${availabilityInputs.length + 1}`;
    setAvailabilityInputs([...availabilityInputs, { id: newId }]);
  };

  //this step is optional
  useEffect(() => {
    onFormValidation(true);
  }, [])

  return (
    <>
        <div className="mb-16 mt-8">
        <h1
          style={{
            fontFamily: "regular",
            textAlign: "center",
            marginTop: "2%",
            marginBottom: "1.25rem",
          }}
        >
          <span className="font-thin">{title[0] || "Select"}</span> <b className="text-default">{title[1] || "your availability"}</b>
        </h1>
        <div className="w-fit ml-auto mr-auto " >
          <div style={avContainer} className="" id="availability_container">
            {availabilityInputs.map((availability, idx) => (
              <AvailabilityInput key={idx} id={availability.id} onFormValidation={onFormValidation} onUpdateFormData={onUpdateFormData}/>
            ))}
          </div>
          <div className="float-left ml-4 cursor-pointer mt-2" onClick={handleAddClick}>
            <FontAwesomeIcon icon={faPlus} size="xl"/>
            <span>{" "}Add</span>
          </div>
        </div>
        </div>

    </>
  );
}

const avContainer: CSS.Properties = {
  maxHeight: '50vh',
  overflow: 'auto'
};
