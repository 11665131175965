import React, { ReactElement } from "react";
import Autocomplete from "react-google-autocomplete";
import { Control, Controller, DeepMap, FieldError, FieldValues, UseFormRegister } from "react-hook-form";
import inputStyles from "./inputStyles.module.css";

interface StreetInputProps {
  control: Control<any>;
  register: UseFormRegister<any>;
  id: string;
  label: string;
  errors: DeepMap<FieldValues, FieldError>;
  selectedCountryCode?: string;
  showValidationErrors?: boolean;
  validation?: FieldValues;
  selectedCity?: string;
}export default function StreetInput({
  control,
  id,
  label,
  errors,
  validation,
  showValidationErrors,
  selectedCountryCode,
  selectedCity,
}: StreetInputProps): ReactElement {
  return (
    <div className="mb-4">
      {validation?.required ? (
        <div>
          {label} *
        </div>
      ) : (
        label
      )}
      <Controller
        name={id}
        control={control}
        rules={validation}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            apiKey={"AIzaSyChdjW5k43txPe2R0PhSlwI2XjCNLSiaYk"} 
            onPlaceSelected={(place) => {
              onChange(place.formatted_address); // assuming you want to store full address
            }}
            options={{
              types: ["address"],
              componentRestrictions: { country: selectedCountryCode ?? "be" },
            }}
            className={`${inputStyles.input} ${errors[id] ? inputStyles.error : ""}`}
            id={id}
          />
        )}
      />
      {(errors[id]) && showValidationErrors && (
        <p className="text-red-600 text-sm mt-1 mb-0">
            {errors[id]?.message}
        </p>
      )}
    </div>
  );
}