import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import User from "../../dto/User.dto";

interface SmallEventCard {
  id: number;
  name: string;
  coverPhoto?: string;
  host: User;
  status: string;
  length: number;
  bookingDate: Date;
  location: string;
  bookerPhoto: string | undefined;
  intervalLength?: number;
  maxHeight?: number;
  maxWidth?: number;
}

export default function EventCard({
  id,
  name,
  coverPhoto,
  location,
  bookingDate,
  bookerPhoto,
  length,
  host,
  intervalLength,
  maxHeight,
  maxWidth,
}: SmallEventCard): ReactElement {
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [day, setDay] = useState<string>("");
  const [month, setMonth] = useState<string>("");

  useEffect(() => {
    const dateObj = new Date(bookingDate);

    const dayOfMonth = dateObj.getDate().toString().padStart(2, "0");
    const options = { month: "short" as const };
    const monthName = dateObj.toLocaleDateString(undefined, options);
    setDay(dayOfMonth);
    setMonth(monthName);

    const hours = dateObj.getHours().toString().padStart(2, "0");
    const minutes = dateObj.getMinutes();
    const formattedStartTime = `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;

    // Calculate end time based on start time and length (in minutes)
    const lengthInMinutes = length;
    const startHours = dateObj.getHours();
    const startMinutes = dateObj.getMinutes();
    const endHours = Math.floor(
      (startHours * 60 + startMinutes + lengthInMinutes) / 60
    );
    const endMinutes = (startMinutes + lengthInMinutes) % 60;

    const formattedEndTime = `${endHours}:${
      endMinutes < 10 ? "0" : ""
    }${endMinutes}`;

    setStartTime(formattedStartTime);
    setEndTime(formattedEndTime);
  }, [bookingDate, length]);

  return (
    <div
      className="bookingCard"
      style={{
        borderRadius: 20,
        width: "100%",
        maxWidth: `${maxWidth || ''}px`,
        maxHeight: `${maxHeight || ''}px`,
        height: "100%",
        backgroundColor: "white",
        boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.16)",
        position: "relative", // Added position relative
        zIndex: 2,
      }}
    >
      <Link key={id} to={""}>
        <div>
          <div style={{ position: "relative" }}>
            <img
              style={{
                borderRadius: 20,
                width: "100%",
                overflow: "hidden",
                height: 175,
                objectFit: "cover",
              }}
              src={coverPhoto}
              alt="event_img"
            ></img>
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                width: "60px",
                height: "60px",
                backgroundColor: "white",
                color: "#19005E",
                borderRadius: 20,
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <p style={{ fontFamily: "bold", fontSize: "1.2em", margin: 0 }}>
                {day}
              </p>
              <p
                style={{
                  fontFamily: "regular",
                  fontSize: "0.8em",
                  margin: 0,
                  marginTop: -5,
                }}
              >
                {month}
              </p>
            </div>
          </div>
        </div>

        <main style={{ padding: 20, color: "#19005E" }}>
          <h3 style={{ fontFamily: "bold", margin: 0 }}>{name}</h3>
          <p style={{ fontFamily: "semiBold", margin: 0 }}>
            {startTime} - {endTime}
          </p>
        </main>
        <span
          style={{
            display: "block",
            width: "80%",
            borderTop: "1px solid #E2DDEF",
            margin: "auto",
          }}
        ></span>
        <footer
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 20,
            color: "black",
            fontFamily: "semiBold",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <p style={{ width: "100%", margin: 0, marginRight: '0.5rem'  }}>
              <FontAwesomeIcon icon={faLocationDot as IconProp} /> {location}
            </p>
            <p style={{ width: "100%", margin: 0}}>
              @{host.firstName + " " + host.lastName}
            </p>
          </div>
          <img
            style={{ borderRadius: 20, width: 50, height: 50 }}
            src={bookerPhoto}
            alt="invited people of yaldo"
          />
        </footer>
      </Link>
      {/* {intervalLength && maxHeight && length > intervalLength && (
        <div
          style={{
            height: `${
              // (Math.floor(length / intervalLength) - 1) * maxHeight
              100
            }px`,
            width: "100%", // Adjust as needed
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: -1, // Set zIndex to -1 to place it below the bookingCard
          }}
          className="event_overflow"
        ></div>
      )} */}
    </div>
  );
}
