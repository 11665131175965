import React, { useEffect, useState } from 'react';
import { Colors } from '../Colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import RenderMonthView from './MonthView';
import RenderWeekView from './WeekView';
import RenderListView from './ListView';
import RenderDayView from './DayView';
import EventService from '../../services/EventService';
import EventBookingDto from '../../dto/EventBooking.dto';

const CalendarView: React.FC = () => {
  const [view, setView] = useState<'month' | 'week' | 'day'>('month');
  const [calendarView, setCalendarView] = useState<'calendar' | 'list'>('calendar');
  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [bookings, setBookings] = useState<EventBookingDto[]>();

  const getBookings = async () => {
    const bookings = await EventService.getBookings();
    setBookings(bookings.sort((a:any, b:any) => new Date(a.date).getTime() - new Date(b.date).getTime()));
  }

  const handleNext = () => {
    if (view === 'month') {
      const nextMonthDate = new Date(currentDate);
      nextMonthDate.setMonth(nextMonthDate.getMonth() + 1);
      setCurrentDate(nextMonthDate);
    } else if (view === 'week') {
      const nextWeekDate = new Date(currentDate);
      nextWeekDate.setDate(nextWeekDate.getDate() + 7);
      setCurrentDate(nextWeekDate);
    } else if (view === 'day') {
      const nextDayDate = new Date(currentDate);
      nextDayDate.setDate(nextDayDate.getDate() + 1);
      setCurrentDate(nextDayDate);
    }
  };

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  const handlePrev = () => {
    if (view === 'month') {
      const prevMonthDate = new Date(currentDate);
      prevMonthDate.setMonth(prevMonthDate.getMonth() - 1);
      setCurrentDate(prevMonthDate);
    } else if (view === 'week') {
      const prevWeekDate = new Date(currentDate);
      prevWeekDate.setDate(prevWeekDate.getDate() - 7);
      setCurrentDate(prevWeekDate);
    } else if (view === 'day') {
      const prevDayDate = new Date(currentDate);
      prevDayDate.setDate(prevDayDate.getDate() - 1);
      setCurrentDate(prevDayDate);
    }
  };  

  const renderCalendar = () => {
    if (calendarView === 'calendar' && bookings) {
      if (view === 'month') {
        return <RenderMonthView currentDate={currentDate} bookings={bookings} />;
      } else if (view === 'week') {
        return <RenderWeekView currentDate={currentDate} bookings={bookings} />;
      } else if (view === 'day') {
        return <RenderDayView currentDate={currentDate} bookings={bookings} />;
      }

      return null;
    } else if (calendarView === 'list' && bookings) {
      return <RenderListView bookings={bookings} />;
    }
  };


  useEffect(() => {
    if(!bookings)
        getBookings();
    console.log(bookings)
}, [bookings])

return (
  <div>
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row", 
          margin: "2%",
          justifyContent: 'flex-start',
          gap: "35px"
        }}
      >
        <h1>my agenda</h1>
        <div
          style={{
            borderRadius: '25px',
            boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.29)",
            width: "fit-content",
          }}
        >
          <button
            onClick={() => setCalendarView('calendar')}
            style={{
              backgroundColor: calendarView === 'calendar' ? '#19005E' : 'transparent',
              color: calendarView === 'calendar' ? 'white' : '#19005E',
              borderRadius: '25px',
              padding: '8px 16px',
              marginRight: '10px',
              cursor: 'pointer',
            }}
          >
            calendar view
          </button>
          <button
            onClick={() => setCalendarView('list')}
            style={{
              backgroundColor: calendarView === 'list' ? '#19005E' : 'transparent',
              color: calendarView === 'list' ? 'white' : '#19005E',
              borderRadius: '25px',
              padding: '8px 16px',
              cursor: 'pointer',
            }}
          >
            list view
          </button>
        </div>
      </div>
      <div
        style={{
          width: '95%',
          marginLeft: "2%",
          borderRadius: 20,
          backgroundColor: Colors.lightPurple,
          padding: '35px',
        }}
      >
        {calendarView !== 'list' && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div >
              <h2 style={{ fontFamily: 'bold', color: '#19005E', position: 'relative', minWidth: '200px' }}>
                {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
              </h2>
            </div>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                color: '#19005E',
              }}
            >
              <button onClick={handlePrev}><FontAwesomeIcon size='lg' icon={faChevronLeft as IconProp} /></button>
              <button onClick={goToToday}><FontAwesomeIcon icon={faCircle as IconProp} /></button>
              <button onClick={handleNext}><FontAwesomeIcon size='lg' icon={faChevronRight as IconProp} /></button>
            </div>
            <div
              style={{
                marginLeft: 'auto', // Add this line to move the div to the rightmost side
                borderRadius: '25px',
                boxShadow: "0px 0px 15px 0px rgba(0,0,0,0.29)",
                width: "fit-content",
                marginBottom: '1%',
              }}
            >
              <button
                onClick={() => setView('month')}
                style={{
                  backgroundColor: view === 'month' ? '#19005E' : 'transparent',
                  color: view === 'month' ? 'white' : '#D1CCDF',
                  borderRadius: '25px',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
              >month</button>
{/*               <button
                onClick={() => setView('week')}
                style={{
                  backgroundColor: view === 'week' ? '#19005E' : 'transparent',
                  color: view === 'week' ? 'white' : '#D1CCDF',
                  borderRadius: '25px',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
              >week</button> */}
              <button
                onClick={() => setView('day')}
                style={{
                  backgroundColor: view === 'day' ? '#19005E' : 'transparent',
                  color: view === 'day' ? 'white' : '#D1CCDF',
                  borderRadius: '25px',
                  padding: '8px 16px',
                  cursor: 'pointer',
                }}
              >day</button>
            </div>
          </div>
        )}
        {renderCalendar()}
      </div>
    </div>
  </div>
);
};

export default CalendarView;
