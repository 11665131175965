import { MollieLinkSubscriptionDto } from "../dto/LinkSubscriptionPlan.dto";
import StoreSignUpDto from "../dto/StoreSignUp.dto";
import { SubscriptionPlanDTO } from "../dto/SubscriptionPlan.dto";
import UpdateStore from "../dto/UpdateStore.dto";
import User from "../dto/User.dto";
import { storeClient } from "./HttpClients";

export default class StoreService {
  public static async updateStore(payload: any, aStore: StoreSignUpDto) {
    try {
      storeClient.interceptors.request.use()
      const response = await storeClient.patch(`${aStore.id}`, payload);
      return response.data;
    } catch (e: any) {
      console.error(e);
    }
  }

  public static async fetchSubscriptionPlans() {
    try {
      const response = await storeClient.get(`/subscription-plan`);
      return response.data;
    } catch (e: any) {
      console.error(e);
    }
  }

  public static async linkSubscriptionPlan(molliePayload: MollieLinkSubscriptionDto) {
    try {
      const response = await storeClient.post(`/link/subscription-plan`, molliePayload);
      return response.data;
    } catch (e: any) {
      console.error(e);
    }
  }
}