/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactElement, Suspense } from "react";
// import { Route} from 'react-router-dom';
import withAuth from "../../hocs/withAuth";
import yaldoIcon from "../../assets/yaldo_icon-text_white.svg";
import yaldoIconWhite from "../../assets/yaldo_icon_white.svg";
import MenuItem from "../../components/skeleton/MenuItem";
import WaitingScreenScene from "../waitingScreen/WaitingScreenScene";
import UserMenu from "../../components/skeleton/UserMenu";
import Search from "../../components/Search";
import Dashboard from "../../components/dashboard/Dashboard";
import { Routes, Route, Navigate } from 'react-router-dom';
import Calendar from "../../components/calendar/Calendar";
import InfoButton from "../../components/InfoButton";
import { Colors } from "../../components/Colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventEdit } from "../../components/EventEdit";
import EditSkeleton from "../../components/dashboard/EditSkeleton";
import ComingSoon from "../../components/ComingSoon";
import { faPaperPlane, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const EventsScreen = React.lazy(() => import("../events/EventsScreen"));
const CreateNewEventScreen = React.lazy(
  () => import("../events/CreateNewEventScreen")
);

function sendEmail() {
  console.log("pressed");
  window.location.href = "mailto:user@example.com?subject=Subject&body=message%20goes%20here";
}

function PageSkeleton(): ReactElement {
  return (
    <div className="flex bg-yaldoWhite w-full h-full">
      <div style={{background: "linear-gradient(0deg, rgba(25,0,94,1) 0%, rgba(53,22,144,1) 80%)", borderRadius: "0 20px 20px 0", width: "20%", position: "relative"}}>
        <div style={{position: "absolute", backgroundImage: `url(${yaldoIconWhite})`, backgroundRepeat: "no-repeat", backgroundSize: "55%", width: "100%", height: "25vh", opacity: 0.05, marginLeft: "-10%", marginTop: "-10%", overflow: "hidden"}}></div>
        <div className="flex justify-center my-8 mt-20" style={{marginBottom: "45%"}}>
          <img className="w-40" src={yaldoIcon} alt="Yaldo" />
        </div>
        <MenuItem label="dashboard" to="/dashboard" />
        <MenuItem label="agenda" to="/calendar" />
        <MenuItem label="chat" to="/chats" />
        <MenuItem label="yaldo's" to="/events" />
        <MenuItem label="store" to="/store" />
        <MenuItem label="team" to="/team" />
        <MenuItem label="statistics" to="/stats" />
        <MenuItem label="library" to="/library" />
        <MenuItem label="settings" to="/settings" />
        <div className="w-full absolute font-semibold h-16 flex items-center justify-center text-white bottom-10">
          <InfoButton text="give us some feedback" backgroundColor={Colors.lightPurple} textColor={Colors.darkPurple}>
              {/* <FontAwesomeIcon icon={icon({name: 'paper-plane', style: 'regular'})} /> */}
              <FontAwesomeIcon icon={faPaperPlane} />
          </InfoButton>
        </div>
      </div>
      <div className="w-full h-full p-3 overflow-y-scroll">
        <Suspense fallback={<WaitingScreenScene secondary />}>
          <div style={{display: "flex", justifyContent: "space-between", width: "98%", margin: "auto", marginTop: "2%"}}>
            <Search />
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginRight: 60}}>
              <InfoButton text="support" backgroundColor={Colors.lightPurple} textColor={Colors.black}>
              {/* <FontAwesomeIcon icon={icon({name: 'question-circle', style: 'regular'})} /> */}
              <FontAwesomeIcon icon={faQuestionCircle} />
              </InfoButton>
              <UserMenu />
            </div>
          </div>
            <Routes>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard/edit-account/" element={<Navigate to="/dashboard/edit-account/experiences" />} />
              <Route path="/dashboard/edit-account/*" element={<EditSkeleton />} />
              <Route path="/events/new" element={<CreateNewEventScreen />} />
              <Route path="/chats" element={<ComingSoon/>}/>
              <Route path="/events/edit/:id" element={<EventEdit />} />
              <Route path="/events" element={<EventsScreen />} />
              <Route path="/calendar" element={<Calendar />} />
              <Route path="/store" element={<ComingSoon/>}/>
              <Route path="/team" element={<ComingSoon/>}/>
              <Route path="/settings" element={<ComingSoon/>}/>
              <Route path="/stats" element={<ComingSoon/>}/>
              <Route path="/library" element={<ComingSoon/>}/>
              <Route path="/settings" element={<ComingSoon/>}/>
              <Route path="/loadingInside" element={<WaitingScreenScene secondary />} />
            </Routes>
        </Suspense>
      </div>
    </div>
  );
}
export default withAuth(PageSkeleton);
