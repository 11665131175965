import { ReactElement, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { useUser } from "../../context/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Colors } from "../Colors";
import { faArrowRightFromBracket, faCalendar, faChevronRight, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
export default function UserMenu(): ReactElement {
  const { state, dispatch } = useUser();
  const [isActive, setIsActive] = useState<boolean>(false);
  const ref = useDetectClickOutside({
    onTriggered: () => setIsActive(false),
  });
  return (
    <div
      ref={ref}>
      <div
        className="cursor-pointer right-20"
        style={{display: "flex", justifyContent: "flex-start"}}
        onClick={() => setIsActive(!isActive)}>
          <div className="bg-yaldoGray transform translate-x-6" style={{borderRadius: '20px', color: 'black', position: 'relative', paddingRight: 50}}>
            <p style={{ fontFamily: "semiBold", textAlign: "left", margin: '10px 0px', padding: "0px 20px", width: "fit-content"}}>{state?.firstName} {state?.lastName}</p>
            {state?.image ? <img  src={state?.image} alt="" className="w-12 h-12 rounded-full" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0}} /> : null }
          </div>


      </div>

      {isActive && (
        // backgroundColor: "linear-gradient(0deg, rgba(25,0,94,1) 0%, rgba(53,22,144,1) 80%)",
          <div className="right-10" style={{background: "linear-gradient(to bottom right, rgba(53,22,144,1) 0%, rgba(25,0,94,1) 100%)", marginTop: 25, borderRadius: "20px", position: "absolute", zIndex: 1, width: "15%" }}>

            <Link to={"/dashboard/edit-account"} className=" text-yaldoLightPurple" style={{width: 250, display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "5%"}}>
              <div style={{aspectRatio: "1", width: 35, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.lightPurple, color: Colors.darkPurple, borderRadius: 50, margin: "10px 15px"}}><FontAwesomeIcon icon={faPenToSquare} /></div> edit my profile <FontAwesomeIcon style={{color: Colors.lightPurple, position: "absolute", right: "10%"}} icon={faChevronRight as IconProp} />
            </Link>
            <Link to={""} className=" text-yaldoLightPurple" style={{width: 250, display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
            <div style={{aspectRatio: "1", width: 35, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.lightPurple, color: Colors.darkPurple, borderRadius: 50, margin: "10px 15px"}}><FontAwesomeIcon icon={faCalendar} /></div> view subsciption plan <FontAwesomeIcon style={{color: Colors.lightPurple, position: "absolute", right: "10%"}} icon={faChevronRight as IconProp}/></Link>
            <button className=" cursor-pointer p-0 text-center text-logout" style={{width: 250, color: Colors.red, display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "5%"}} onClick={() => dispatch({ type: "signOut" })}>
            <div style={{aspectRatio: "1", width: 35, display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.red, color: Colors.lightPurple, borderRadius: 50, margin: "10px 15px"}}><FontAwesomeIcon icon={faArrowRightFromBracket as IconProp} /> </div> log out <FontAwesomeIcon style={{color: Colors.red, position: "absolute", right: "10%"}} icon={faChevronRight as IconProp} /></button>
          </div>
          )}
    </div>
  );
}
