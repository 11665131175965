import React from 'react';
import EventBookingDto from "../../dto/EventBooking.dto";
import EventCard from '../dashboard/EventCard';
import { Colors } from '../Colors';

// Voeg types toe voor groupedBookings en bookings
type GroupedBookings = {
  [key: string]: EventBookingDto[]; // De sleutels zijn strings en de waarden zijn arrays van EventBookingDto
};

interface RenderListViewProps {
  bookings: EventBookingDto[];
}

const RenderListView: React.FC<RenderListViewProps> = ({ bookings }) => { // Geef RenderListView een type
  // Sorteer de boekingen op datum
  const sortedBookings = [...bookings].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  // Houd de huidige maand bij om secties te maken
  let currentMonth = '';
  const groupedBookings: GroupedBookings = {};

  // Groepeer de boekingen per maand en maak secties
  sortedBookings.forEach((booking: EventBookingDto) => {
    const bookingDate = new Date(booking.date);
    const year = bookingDate.getFullYear();
    const month = bookingDate.toLocaleDateString('default', { month: 'long' });

    // Combineer maand en jaar tot één sleutel
    const yearMonth = `${month} ${year}`;

    if (yearMonth !== currentMonth) {
      // Maak een nieuwe sectie voor de huidige maand
      currentMonth = yearMonth;
      groupedBookings[currentMonth] = [];
    }

    groupedBookings[currentMonth].push(booking);
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {Object.keys(groupedBookings).map((yearMonth) => (
        <div key={yearMonth}>
          <h2 style={{
            fontFamily: 'bold',
            color: Colors.darkPurple,
            marginTop: '18px',
          }}>{yearMonth}</h2>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: 40}}>
            {groupedBookings[yearMonth].map((booking: EventBookingDto) => (
              <EventCard
                key={booking.id}
                id={booking.id}
                coverPhoto={booking.eventVersion.images[0]}
                name={booking.eventVersion.name}
                host={booking.host}
                status={booking.status}
                length={booking.eventVersion.length}
                bookingDate={new Date(booking.date)}
                location={booking.address}
                bookerPhoto={booking.host.image}
                maxWidth={350}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RenderListView;
