import { ReactElement } from "react";
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import { Link } from "react-router-dom";
import { Colors } from "../Colors";
import CSS from "csstype";

interface Content {
    padding: number;
    color?: Colors;
    contentTitle?: string;
    linkName?: string;
    linkTo?: string;
    wrap?: boolean;
    gap?: number;
    children: any;
    width: number;
}

export default function ContentWrapper({ width, color = Colors.lightPurple, contentTitle, wrap = false, gap = 0, linkName, linkTo, children, padding }: Content): ReactElement {
    const wrapperStyle: CSS.Properties = {
        display: "inline-flex",
        flexDirection: "row",
        backgroundColor: color,
        borderRadius: '20px',
        width: `${width}%`
    };

    const containerStyle: CSS.Properties = {
        padding: padding.toString() + "px",
        width: "100%"
    };

    const titleStyle: CSS.Properties = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        color: color === Colors.lightPurple ? Colors.darkPurple : Colors.lightPurple,
        alignItems: 'center',
        justifyContent: "space-between",
        marginBottom: '20px'
    };

    return (
        <div style={wrapperStyle}>
            <div style={containerStyle}>
                <div style={titleStyle}>
                    {contentTitle && <h2 style={{ fontFamily: 'semibold' }}>{contentTitle}</h2>}
                    {linkTo && <Link to={linkTo}>{linkName} <MdOutlineKeyboardArrowRight style={{ display: 'inline-block', marginTop: -4 }} size='25' /></Link>}
                </div>
                <main style={{ width: "auto", display: "flex", flexDirection: "row", flexWrap: wrap ? "wrap" : "nowrap", justifyContent: wrap ? "space-around" : "space-between", gap: gap }}>
                    {children}
                </main>
            </div>
        </div>
    );
}
