import CSS from 'csstype';
import { useEffect, useState } from "react";
import { EmotionDto } from "../../dto/Emotion.dto";
import EventService from "../../services/EventService";
import { SubmitHandler } from "react-hook-form";
import EmotionCardComponent from '../../components/EmotionCardComponent';


interface Props {
  onFormValidation: (isValid: boolean) => void;
  onUpdateFormData: (data: any) => void;
}

export default function Step1({onFormValidation, onUpdateFormData}: Props) {

  const [emotions, setEmotions] = useState<EmotionDto[] | null>(null);
  const [selectedEmotions, setSelectedEmotions] = useState<number[]>([]);

  const selectEmotion = (emotionId: number) => {
    if (selectedEmotions.includes(emotionId)) {
      setSelectedEmotions(selectedEmotions.filter((id) => id !== emotionId));
    } else {
      setSelectedEmotions([...selectedEmotions, emotionId]);
    }
  };

  const checkFormValidity = () => {
    const isValid = selectedEmotions.length > 0;
    onFormValidation(isValid);
  };

  useEffect(() => {
    const fetchAndSetEmotions = async () => {
      try {
        const _emotions = await EventService.getAllEmotions();
        setEmotions(_emotions);
      } catch (error) {
        console.error("Failed to fetch emotions:", error);
        // handle the error, for example show a user-friendly error message
      }
    };
    fetchAndSetEmotions();
  }, []);


  const handleInputChange = () => {
    onUpdateFormData({ selectedEmotions });
  };
  

  const onSubmit: SubmitHandler<EmotionDto> = async (data: EmotionDto) => {
    // ... other code
  
    onUpdateFormData({
      ...data,
      preferedEmotions: selectedEmotions.length ? selectedEmotions : undefined,
    });
  };
  

  useEffect(() => {
    checkFormValidity();
    onUpdateFormData({ preferedEmotions: selectedEmotions });
  }, [selectedEmotions]);

    return(
    <>
    <main style={{marginTop: "5%"}}>
        <h1 style={{ fontFamily: "regular", textAlign: "center", marginTop: "2%", marginBottom: "5%" }}>What <b className="text-default">kind of experiences</b> would you like to offer?</h1>


        <div style={ContentWrapper}>
          {emotions && (
            <div style={EmotionGrid}>
              {emotions.map((emotion) => (
                <EmotionCardComponent
                  key={emotion.id}
                  emotion={{...emotion, id: emotion.id.toString()}} 
                  isSelected={selectedEmotions.includes(emotion.id)} 
                  onClick={() => selectEmotion(emotion.id)}
                />
              ))}
            </div>
          )}
        </div>

    </main>
    </>
    )
}

const ContentWrapper : CSS.Properties = {
    width: "50%",
    margin: 'auto',
    height: '50vh',
    /* height: 'calc(520px)', */
    overflowY: 'auto',
    display: 'flex',  // new
    justifyContent: 'center',  // new
}

const EmotionGrid : CSS.Properties = {
    width: "100%",
    flexWrap: "wrap",
    padding: "6px",
    display: "flex",
    justifyContent: "center",  // updated from 'space-between'
    flexDirection: "row",
    gap: "20px",
}