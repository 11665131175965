/* eslint-disable @typescript-eslint/no-unused-vars */
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSS from 'csstype';
import { isValidPhoneNumber } from 'react-phone-number-input';
import Input from '../forms/Input';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import CountrySelector from '../forms/CountrySelector';
import { useUser } from '../../context/UserContext';
import UserService from '../../services/UserService';
import UpdateUser from '../../dto/UpdateUser.dto';
import WaitingScreenScene from '../../scenes/waitingScreen/WaitingScreenScene';
import User from '../../dto/User.dto';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ToastMessageType, createToast } from '../Notifications';

type PersonalInfo = {
  lastName: string;
  firstName: string;
  country: string;
  phone: string;
  dateOfBirth: string;
  address: string;
  email: string;
  password: string;
  repeatPassword: string;
}

export const EditProfile = () => {
    const {
        register,
        formState: { errors },
        reset, watch
      } = useForm<PersonalInfo>({ mode: "onChange" });
      const { state } = useUser();
      const [isLoading, setIsLoading] = useState(false);
      const [user, setUser] = useState<User>();
      const [userInput, setUserInput] = useState<PersonalInfo>();
      const [passwordMatchError, setPasswordMatchError] = useState(false);
      const watchFields = watch();

      const navigate = useNavigate();
      

      const handleUserInput = async(data: PersonalInfo) => {
        setUserInput((prevState) => ({...prevState, ...data}))
      }

      useEffect(() => {
        handleUserInput(watchFields);
      }, [JSON.stringify(watchFields)]);

      const fetchUser = async (id?: number) => {
        setIsLoading(true);
        try {
          const userData = await UserService.getUser(id);
          setUser(userData);
          //console.log(userData);
          const dateString = userData.dateOfBirth;
          const [day, month, year] = dateString.split("/").map(Number);
          const validDate = new Date(year, month - 1, day);
          const validDateObject = validDate;
          reset({
            lastName: userData.lastName,
            firstName: userData.firstName,
            email: userData.email,
            phone: userData.phone,
            country: userData.country,
            dateOfBirth: dateString,
          });
          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);
          createToast("Er ging iets mis bij het ophalen van de gebruikersgegevens.", ToastMessageType.Error);
        }
      };

      const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
      
        return `${day}/${month}/${year}`;
      }
    
      const onSubmit = async (e:any) => {
        e.preventDefault()
        let newDate;
        let formattedDateOfBirth;
        if(passwordMatchError) {
          createToast("The passwords do not match", ToastMessageType.Error);
          return;
        }
        if(userInput?.dateOfBirth){
          newDate = new Date(userInput?.dateOfBirth!)
          if (!isNaN(newDate.getTime())) {
            formattedDateOfBirth = formatDate(newDate);
          }
        } else formattedDateOfBirth = state?.dateOfBirth

        const updateUser: UpdateUser = {
          id: state?.id,
          firstName: userInput?.firstName,
          lastName: userInput?.lastName,
          email: userInput?.email,
          dateOfBirth: formattedDateOfBirth,
          address: userInput?.address,
          country: userInput?.country,
          phone: userInput?.phone,
          password: userInput?.password
        };
        try {
          console.log(updateUser)
          const res = await UserService.updateUser(updateUser);
          if(res.status === 200){
            createToast("Your profile has been succesfully updated!", ToastMessageType.Success);
          }
          //navigate("/events");
        } catch (err) {

          createToast("Er ging iets mis bij het bijwerken van de gebruikersgegevens.", ToastMessageType.Error);
        }
      };

      useEffect(() => {
        fetchUser(state?.id);
      }, []);

      useEffect(() => {
        const handlePasswordMatch = () => {
          if (userInput?.password !== userInput?.repeatPassword) {
            setPasswordMatchError(true);
          } else {
            setPasswordMatchError(false);
          }
        };
        handlePasswordMatch();
      }, [userInput?.password, userInput?.repeatPassword]);
      
  
      if (isLoading) {
        return <WaitingScreenScene secondary />;
      } else{
        return(
          <main>
              <h1 style={{ fontFamily: "regular", textAlign: "center", marginTop: "2%", marginBottom: "5%" }}>Fill in your <b style={{fontFamily: "semiBold"}}>personal</b> information</h1>
              <form style={{width: "95%", margin: "auto"}}>
              <div style={WidthInputWrapper}>
                <Input type={"text"} label={"Last name"} id={"lastName"} register={register} errors={errors} validation={{
                    required: "Dit veld is verplicht",
                  }}/>
                <Input type={"text"} label={"First name"} id={"firstName"} register={register} errors={errors} validation={{
                    required: "Dit veld is verplicht",
                  }}/>
                  </div>
                  <div style={WidthInputWrapper}>
                {/* <Input label={"Country"} id={"country"} register={register} errors={errors} validation={{
                    required: "Dit veld is verplicht",
                  }}/> */}
  
                <CountrySelector countryCode={user?.country} label={'Country'} id={'country'} register={register} errors={errors} validation={{
                  required: "Dit veld is verplicht"
                }}/>
                  
                <Input label={"Phone number"} id={"phone"} register={register} errors={errors} validation={{
                  required: "This field is required.",
                  validate: (value: string) =>
                    isValidPhoneNumber(value)
                      ? true
                      : "Please enter a valid phone number in the following format: +32412345678.",
                }}/>
                  </div>
                  <div style={WidthInputWrapper}>
                <Input type="date" label={"Date of birth"} id={"dateOfBirth"} register={register} errors={errors} validation={{
                    required: "Dit veld is verplicht",
                  }}/>
                <Input type="email" label={"Email"} id={"email"} register={register} errors={errors} validation={{
                    required: "Dit veld is verplicht",
                  }}/>
                  </div>
                  <div style={WidthInputWrapper}>
                <Input type="password" label={"Password"} id={"password"} register={register} errors={errors} validation={{
                    required: "Dit veld is verplicht",
                  }}/>
                <Input type="password" label={"Confirm Password"} id={"repeatPassword"} register={register} errors={errors} validation={{
                    required: "Dit veld is verplicht",
                  }}/>  
                  </div>
                  <div className="w-full h-full flex items-end justify-center">
                      <div className="flex justify-between absolute" style={{width: "350px", margin: "auto", bottom: "10%"}}>
                          <button
                              className="bg-yaldoWhite text-yaldoDarkBlue hover:bg-yaldoDarkBlue hover:text-white border border-yaldoDarkBlue flex justify-between items-center"
                              style={{ padding: "10px 40px", borderRadius: 20 }}
                              type="submit"
                              onClick={onSubmit}

                          >
                              <FontAwesomeIcon icon={faBookmark as IconProp} className="mr-2" />
                              <span>Opslaan</span>
                          </button>
  
  
                              <button
                              className="bg-yaldoWhite text-logout hover:bg-cancel hover:text-white border border-cancel"
                              style={{padding: "10px 40px", borderRadius: 20}}
                              type="button"
                              // onClick={() => history.replace("/events")}
                              onClick={() =>navigate("/events")}
                              >
                              Annuleren
                              </button>
                      </div>
                  </div>
              </form>
          </main>
      )
      }
    
}

const WidthInputWrapper : CSS.Properties = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
}