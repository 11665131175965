import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import StoreSignUpDto from "../../dto/StoreSignUp.dto";
import verif1 from '../../assets/verif1.png'
import verif2 from '../../assets/verif2.png'
import verif3 from '../../assets/verif3.png'
import CustomDropZone from "../../components/forms/CustomDropZone";
import { ToastMessageType, createToast } from "../../components/Notifications";

interface Props {
  onFormValidation: (isValid: boolean) => void;
  showValidationErrorsRef: React.MutableRefObject<(value: boolean) => void>;
  onUpdateFormData: (data: any) => Promise<void>;
  storeFormData: StoreSignUpDto;
}

interface IVerificationSteps {
  stepTitle: string;
  stepDescription: string;
  stepImage: string;
  stepId: string;
}

const verificationSteps = (
  companyName: string,
  businessName: string
): IVerificationSteps[] => {
  return [
    {
      stepTitle: "Verify your existence",
      stepDescription: `Upload a picture of the ID of "Company legal representative name of "${companyName}".`,
      stepImage: verif1,
      stepId: 'idImg'
    },
    {
      stepTitle: "Verify your business",
      stepDescription: `Upload a picture that proofs you are a legal representative of "${companyName}".`,
      stepImage: verif2,
      stepId: 'proofImg'
    },
    {
      stepTitle: "Verify your banking details",
      stepDescription: `Upload a picture of the ID owner of "${businessName}".`,
      stepImage: verif3,
      stepId: 'ownerIdImg'

    },
  ];
};

type ImageFieldNames =  'idImg'| 'proofImg' | 'ownerIdImg';

export default function Step6 ({
  onFormValidation,
  showValidationErrorsRef,
  storeFormData,
  onUpdateFormData
}: Props) {
  const {
    register,
    formState: { errors }, watch, setValue
  } = useForm<StoreSignUpDto>({ mode: "onChange" });

  const [images, setImages] = useState<any[]>([]);
  const watchFields = watch(['idImg', 'ownerIdImg', 'proofImg']);
  const [showValidationErrors, setShowValidationErrors] = useState(false);

  function handleInputChange() {
    onUpdateFormData(watchFields);
  }

  const addImages = (updatedImages: File[], fieldId: string) => {
    setImages(updatedImages);
    setValue(fieldId as ImageFieldNames, updatedImages)
    storeFormData[fieldId as ImageFieldNames] = updatedImages
  };

  const removeImage = (updatedImages: File[], fieldId:string) => {
    setImages([...updatedImages, ...images]);
    setValue(fieldId as ImageFieldNames, updatedImages)
    storeFormData[fieldId as ImageFieldNames] = updatedImages
  };

  function validateInput(){
    if ((watchFields[0] && watchFields[0]?.length > 0) &&
      (watchFields[1] && watchFields[1]?.length > 0) &&
      (watchFields[2] && watchFields[2]?.length > 0)) {
      onFormValidation(true);
      createToast("Alle vereiste documenten zijn geüpload",ToastMessageType.Success);
    } else {
      onFormValidation(false);
    }
  }

  useEffect(() => {
    //showValidationErrorsRef.current = setShowValidationErrors;
    validateInput()
    console.log(watchFields)
  }, [JSON.stringify(watchFields)]);

/*   useEffect(() => {
    onFormValidation(true);
  },[images]) */

  return (
    storeFormData && (<div className="mt-16 pb-3 flex flex-col grid-areas[header contentDiv] ">
    <div className="grid-header justify-center flex  ">
        <h2 className="text-[24px] xl:text-[36px]"><span className="font-thin">Final</span> <b>verification</b></h2>
    </div>
    <div className="flex justify-center p-4 flex-row gap-6 grid-contentDiv grid-areas[stepHeader stepContent] grid-rows-[10%,90%] ">
      {verificationSteps((storeFormData.companyName || "Company name"), (storeFormData.companyRepresentative||"Business name")).map((step, idx) => (
        <div key={idx} className="max-w-[25%] 	">
          <div>
            <h3>{`${idx + 1}. ${step.stepTitle}`}</h3>
            <p className="max-w-[80%]">{step.stepDescription}</p>
          </div>

          <div className="h-[100%] pb-16">
            <div className="flex flex-col p-4 justify-center  ml-4 rounded-xl border border-[#c9c9c9] h-[100%] max-h-[320px] w-[100%] max-w-[320px] 	" >
              <CustomDropZone<StoreSignUpDto>
                uploadId={step.stepId}
                addImages={addImages}
                removeImage={removeImage}
                register={register}
                errors={errors}
                validation={{
                  required: "Dit veld is verplicht",
                }}
                placeHolderImage={step.stepImage}
                initialImages={[]}
                {...register(step.stepId as ImageFieldNames)}
                maxItems={2}
                custom={true}

              />

            </div>
          </div>
        </div>
      ))}
    </div>
  </div>)
  );
};
