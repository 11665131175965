import CSS from "csstype";

const ComingSoon = () => {
    const wrapper: CSS.Properties = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
    };

    const h1Style: CSS.Properties = {
        fontFamily: "regular",
        margin: "2% 0 0"
    };

    return (
      <div style={wrapper}>
        <h1 style={h1Style} className="text-yaldoDarkBlue">This feature is coming soon</h1>
      </div>
    );
}

export default ComingSoon;
