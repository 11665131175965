import CSS from 'csstype';
import Input from "../../components/forms/Input";
import { useForm } from "react-hook-form";
import { UserSignUpDto } from "../../dto/UserSignUp.dto";
import { useEffect, useState } from "react";
import CountrySelector from '../../components/forms/CountrySelector';
import parsePhoneNumberFromString, { CountryCode } from 'libphonenumber-js';

interface Props {
  onFormValidation: (isValid: boolean) => void;
  showValidationErrorsRef: React.MutableRefObject<(value: boolean) => void>;
  onUpdateFormData: (data: any) => void;
  userData?: UserSignUpDto
}

export interface ICountryData {
  countryCode: CountryCode;
  fullName: string;
}

// Function to convert "dd/mm/yyyy" to "yyyy-mm-dd" format
function formatDateToISO(dateString:string) {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month}-${day}`;
}

export default function Step2({onFormValidation, showValidationErrorsRef, onUpdateFormData, userData}: Props) {

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError, reset, setValue, clearErrors
  } = useForm<PersonalInfo>({ mode: "onChange" });

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState<ICountryData>();
  const [selectedDate, setSelectedDate] = useState<string|undefined>(undefined);

  const [phoneNumberExampleMessage, setPhoneNumberExampleMessage] = useState<string>();

  const watchFields = watch();

  type PersonalInfo = {
    lastName: string;
    firstName: string;
    country: string;
    phone: string;
    dateOfBirth: string;
    email: string;
    password: string;
    confirmPassword: string;
  }
  
  const checkFormValidity = () => {
    let isValid = true;
    for (const [key, value] of Object.entries(watchFields)) {
      if (value === "" || !value) {
        setError(key as keyof PersonalInfo, { type: "required", message: "Dit veld is verplicht" });
        isValid = false;
      }
    }

    const pw = watchFields.password
    const rpw = watchFields.confirmPassword
    if(pw !== rpw) {
      isValid = false;
      onFormValidation(false);
      setError("password", {
        type: "invalidPasswords",
        message: "Password don't match",
      });
    }
    
    // Custom validation for the phone number field
    const phoneNumber = watchFields.phone;
    const validationResult = validatePhoneNumber(phoneNumber, selectedCountryCode?.countryCode, setPhoneNumberExampleMessage);
    if (typeof phoneNumber === "string" && selectedCountryCode && validationResult !== true) {
      setError("phone", {
        type: "invalidPhoneNumber",
        message: validationResult,
      });
      isValid = false;
    //  setShowValidationErrors(true)
    }
    onFormValidation(isValid);
    if(isValid) setShowValidationErrors(false)
  };

  const onCountryChange = (country: ICountryData) => {
    setSelectedCountryCode(country);
    userData!.countryName = country.fullName
    clearErrors('country')
    //onUpdateFormData({countryName: country.fullName})
    checkFormValidity();
  };
  
  const validatePhoneNumber = (value: string, selectedCountryCode: CountryCode | undefined, setPhoneNumberExampleMessage: (message: string) => void) => {
    setPhoneNumberExampleMessage('');
    
    if (!selectedCountryCode) {
      //console.log('selectedCountryCode is undefined');
      return "Please select a country.";
    }
  
    const phoneNumber = parsePhoneNumberFromString(value, selectedCountryCode);
  
    if (!phoneNumber || !phoneNumber.isValid()) {
      console.log("Invalid phone number");
      return "Please enter a valid phone number.";
    }
  
    const countryCodeAndPlus = `+${phoneNumber.countryCallingCode}`;
    const isValidPrefix = value.startsWith(countryCodeAndPlus);
  
    if (!isValidPrefix) {
      console.log("Invalid phone number prefix");
      const errorMessage = `Please enter a valid phone number for the selected country with the correct prefix. Example: ${countryCodeAndPlus}`;
      setPhoneNumberExampleMessage(errorMessage);
      return errorMessage;
    }

    return true;
  };
  
  
  const confirmPasswordValidation = {
    required: "Dit veld is verplicht",
    validate: {
      passwordsMatch: (value: string) =>
        value === watch("password") || "Passwords do not match",
    },
  };

  const handleInputChange = () => {
    onUpdateFormData(watchFields);


    if(watchFields.confirmPassword === watchFields.password){
      clearErrors('confirmPassword')
      clearErrors('password')
    }
  };

  const handleDateInputChange = () => {
    if(watchFields.dateOfBirth && watchFields.dateOfBirth !== ""){
      setSelectedDate(watchFields.dateOfBirth)
    }
  }

  useEffect(() => {
    if (errors.phone && errors.phone.message) {
      setPhoneNumberExampleMessage(errors.phone.message);
    }
  }, [errors.phone]);

  useEffect(() => {
    showValidationErrorsRef.current = setShowValidationErrors;
    setShowValidationErrors(true)

    handleInputChange();
    handleDateInputChange();
    checkFormValidity()
  }, [JSON.stringify(watchFields)]);


  useEffect(() => {
    const previousUserData = {
      lastName: userData?.lastName,
      firstName: userData?.firstName,
      country: userData?.country,
      phone: userData?.phone,
      dateOfBirth: userData?.dateOfBirth,
      email: userData?.email,
      password: userData?.password,
      confirmPassword: userData?.password
    }
    reset(previousUserData)
    setValue('country', previousUserData.country!)
    setValue('dateOfBirth', previousUserData.dateOfBirth!)
    setSelectedCountryCode({ countryCode: userData?.country!, fullName: userData?.countryName! } as ICountryData)
  }, [])

  useEffect(() => {
    if(userData && userData.dateOfBirth){
      watchFields.dateOfBirth = userData.dateOfBirth
      const isoDate = formatDateToISO(userData.dateOfBirth!);
      setSelectedDate(isoDate)
    }
  }, [userData])

    return(
        <main style={{marginTop: "5%"}}>
        <h1 style={{ fontFamily: "regular", textAlign: "center", marginTop: "2%", marginBottom: "5%" }}>Fill in your <b className="text-default">personal</b> information</h1>


        <div style={ContentWrapper}>
        <div className="mb-4 w-full" style={RowInput}>
            <form>
              <div style={WidthInputWrapper}>
              <Input type={"text"} label={"Last name"} id={"lastName"} register={register} errors={errors} showValidationErrors={showValidationErrors} validation={{
                  required: "Dit veld is verplicht",
                }}/>
              <Input type={"text"} label={"First name"} id={"firstName"} register={register} errors={errors} showValidationErrors={showValidationErrors} validation={{
                  required: "Dit veld is verplicht",
                }}/>
                </div>
                <div style={WidthInputWrapper}>
              <CountrySelector label={'Country'} id={'country'} register={register} errors={errors} clearErrors={clearErrors} showValidationErrors={showValidationErrors} onCountryChange={onCountryChange} validation={{
                  required: "Dit veld is verplicht"
                }} countryCode={selectedCountryCode?.countryCode || userData?.country} countryName={selectedCountryCode?.fullName || userData?.countryName}/>
                
                <Input
                  label={"Phone number"}
                  type={"text"}
                  id={"phone"}
                  register={register}
                  errors={errors}
                  showValidationErrors={showValidationErrors}
                  phoneNumberExampleMessage={phoneNumberExampleMessage}
                  validation={{
                    required: "This field is required.",
                    validate: (value: string) => {
                      console.log("Entering validation function for phone number");
                  
                      const validationResult = validatePhoneNumber(value, selectedCountryCode?.countryCode, setPhoneNumberExampleMessage);
                      return validationResult === true ? undefined : validationResult;
                    },
                  }}                                                         
                />
                </div>
                <div style={WidthInputWrapper}>
                <Input type="date" label={"Date of birth"} id={"dateOfBirth"} register={register} errors={errors} showValidationErrors={showValidationErrors} validation={{
                    required: "Dit veld is verplicht",
                }} />


              <Input type="email" label={"Email"} id={"email"} register={register} errors={errors} showValidationErrors={showValidationErrors} validation={{
                  required: "Dit veld is verplicht",
                }}/>
                </div>
                <div style={WidthInputWrapper}>
              <Input type="password" label={"Password"} id={"password"} register={register} errors={errors} showValidationErrors={showValidationErrors} validation={{
                  required: "Dit veld is verplicht",
                  pattern: {
                    value: /(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{8}$/i,
                    message:
                      "Password must contain at least one number, one lowercase and one uppercase letter and must be at least 8 characters long.",
                  },
                }}/>
              <Input type="password" label={"Confirm Password"} id={"confirmPassword"} register={register} errors={errors} showValidationErrors={showValidationErrors} validation={confirmPasswordValidation}/>  
                </div>
            </form>
          </div>
        </div>


    </main>
    )
}


const ContentWrapper : CSS.Properties = {
    width: "60%",
    margin: 'auto',
}

const RowInput : CSS.Properties = {
  // display: "flex",
  // justifyContent: "space-between",
  // flexGrow: 1
}

const WidthInputWrapper : CSS.Properties = {
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  flexGrow: 1
}

