import React from 'react';
import EventBookingDto from '../../dto/EventBooking.dto';

const RenderWeekView = ({ currentDate, bookings }: { currentDate: Date, bookings: EventBookingDto[] }) => {
  const daysInWeek = 7;
  const divHeight = '65vh'; // Aanpassen aan de gewenste hoogte van de div
  const weekDays = [];

  const getWeekDates = (date: Date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    const dayOfWeek = d.getDay() || 7;
    d.setDate(d.getDate() + 1 - dayOfWeek);
    const startDate = new Date(d);

    d.setDate(d.getDate() + 6);
    const endDate = new Date(d);

    return { startDate, endDate };
  };

  const { startDate, endDate } = getWeekDates(currentDate);

  // Voeg een lege kop cel toe voor de urenkolom
  weekDays.push(
    <th
      key="hour"
      style={{
        border: '1px solid white',
        backgroundColor: '#19005E',
        padding: '5px',
        textAlign: 'center',
        width: '10%', // Even breedte voor urenkolom
        minWidth: '50px',
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      &nbsp;
    </th>
  );

  for (let day = 0; day < daysInWeek; day++) {
    const dayDate = new Date(startDate);
    dayDate.setDate(startDate.getDate() + day);

    weekDays.push(
      <th
        key={day}
        style={{
          border: '1px solid white',
          padding: '10px',
          textAlign: 'center',
          width: `${90 / daysInWeek}%`, // Even breedte voor de dagen van de week
          minWidth: '50px',
          top: 0,
          backgroundColor: '#19005E',
          color: 'white',
          position: 'sticky',
          zIndex: 1,
        }}
      >
        {dayDate.getDate()} {dayDate.toLocaleDateString('default', { weekday: 'long' })}
      </th>
    );
  }

  // Inhoud voor elk uur van de dag
  const weekContent = [];

  for (let hour = 0; hour < 24; hour++) {
    const hourRow = [];

    // Voeg de uren toe aan de urenkolom
    hourRow.push(
      <td
        key={`hour-${hour}`}
        style={{
          border: '1px solid #C8C3D9',
          padding: '5px',
          height: 80,
          textAlign: 'center',
          width: '10%', // Even breedte voor urenkolom
          position: 'sticky',
          left: 0,
          zIndex: 1,
          backgroundColor: '#F3F2F7',
        }}
      >
        {hour}:00
      </td>
    );

    for (let day = 0; day < daysInWeek; day++) {
      const dayDate = new Date(startDate);
      dayDate.setDate(startDate.getDate() + day);

      // Zoek boekingen voor de huidige dag en uur
      const bookingsForHour = bookings.filter((booking) => {
        const bookingDate = new Date(booking.date);
        return (
          bookingDate.getDate() === dayDate.getDate() &&
          bookingDate.getHours() === hour
        );
      });

      // Bereken de hoogte van de cel op basis van het aantal boekingen
      const cellHeight = `${bookingsForHour.length * 20}px`; // Aangenomen dat elke boeking 20px hoog is

      // Maak een div om de boekingen weer te geven
      const bookingDivs = bookingsForHour.map((booking, index) => (
        <div
          key={index}
          style={{
            backgroundColor: '#007acc',
            color: 'white',
            borderRadius: '4px',
            padding: '2px',
            marginBottom: '2px',
          }}
        >
          {booking.eventVersion.name}
        </div>
      ));

      hourRow.push(
        <td
          key={`${day}-${hour}`}
          style={{
            border: '1px solid #C8C3D9',
            padding: '5px',
            height: cellHeight,
            textAlign: 'center',
            width: `${90 / daysInWeek}%`, // Even breedte voor de dagen van de week
          }}
        >
          {bookingDivs}
        </td>
      );
    }

    weekContent.push(<tr key={hour}>{hourRow}</tr>);
  }

  return (
    <div style={{ overflowX: 'hidden', maxHeight: divHeight }}>
      <table
        style={{
          borderCollapse: 'collapse',
          backgroundColor: '#F3F2F7',
          width: '100%',
        }}
      >
        <thead>
          <tr>{weekDays}</tr>
        </thead>
        <tbody>{weekContent}</tbody>
      </table>
    </div>
  );
};

export default RenderWeekView;
