import { ReactElement } from "react";
import { Colors } from "../Colors";
import CSS from "csstype";

export default function Quote({ img }: { img: string }): ReactElement {

    const quoteContainerStyle: CSS.Properties = {
        position: "relative",
        width: "250px",
        aspectRatio: "initial",
        color: Colors.darkPurple,
        fontFamily: "regular",
    };

    const backgroundImageStyle: CSS.Properties = {
        position: "absolute",
        top: "-45px",
        left: "-30px",
        width: "120%",
        height: "90%",
        backgroundImage: `url(${img})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "fit",
        opacity: 0.1,
    };

    return (
        <div style={quoteContainerStyle}>
            <div style={backgroundImageStyle}></div>
            <h2>Fill your life with experiences.
            Not things.
            <br></br>
                Have stories to tell,
            Not stuff to show.
            </h2>
            <p>~ Jordy Callens, 2022</p>
        </div>
    )
}
