export enum EventLocation {
    ONLY_IN_STORE = "ONLY_IN_STORE",
    ONLY_AT_CLIENT = "ONLY_AT_CLIENT",
    STORE_OR_CLIENT = "STORE_OR_CLIENT",
  }
  
  export enum PriceType {
    FREE = "FREE",
    FIXED_PRICE = "FIXED_PRICE",
    PRICE_PER_ATTENDEE = "PRICE_PER_ATTENDEE",
    FIXED_ATTENDEE = "FIXED_ATTENDEE",
  }
  
  export enum CompensationType {
    NONE = "NONE",
    BOOKING = "BOOKING",
    SALE = "SALE",
    BOOKING_SALE = "BOOKING_SALE",
  }
  
  export enum BookingCompensationType {
    POINTS = "POINTS",
    PRODUCT = "PRODUCT",
    FREE_TEXT = "FREE_TEXT",
  }
  
  export enum EventStatus {
    VISIBLE = "VISIBLE",
    HIDDEN = "HIDDEN",
  }