import React, { ReactElement, useEffect, useState } from "react";
import { DeepMap, FieldError, FieldValues, UseFormReturn } from "react-hook-form";
import { ICountryData } from "../../scenes/signUp/Step2";
import { CountryCode } from "libphonenumber-js";

export interface IProps {
  label: string;
  countryCode?: string;
  countryName?: string;
  id: string;
  register: Function;
  errors: DeepMap<FieldValues, FieldError>;
  showValidationErrors?: boolean;
  clearErrors?: Function;
  validation?: FieldValues;
  setValue?: Function;
  watch?: UseFormReturn<FieldValues>["watch"];
  onCountryChange?: (countryCode: ICountryData) => void;
}

export default function CountrySelector({
  label,
  id,
  countryCode,
  countryName,
  register,
  errors,
  validation,
  showValidationErrors,
  onCountryChange,
  setValue,
  watch, clearErrors
}: IProps): ReactElement {
  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(countryCode);
  
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        const data = await response.json();
        const sortedCountries = data.sort((a: any, b: any) =>
        a.name.common.localeCompare(b.name.common)
      );
      setCountries(sortedCountries);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);

  useEffect(() => {
    if (countryCode !== undefined) {
      setSelectedCountryCode(countryCode);
    }
  }, [countryCode]);

  useEffect(() => {
     if (selectedCountryCode) {
      if (setValue) {
        setValue(id, selectedCountryCode, { shouldValidate: true });
      }
      if (onCountryChange) {
        clearErrors!('country')
      }
    } 
  }, [selectedCountryCode]);
  
  

  return (
    <div className="flex flex-col mb-4 w-full">
      <label
        className="color-gray"
        style={{ fontFamily: "regular", fontSize: "1em" }}
        htmlFor={id}
      >
        {validation?.required ? (
          <div>
            {label} *
          </div>
        ) : (
          label
        )}
      </label>
      <select
        {...register(id, { ...validation, value: selectedCountryCode })}
        id={id}
        value={selectedCountryCode}
        onChange={(e) => {
          setSelectedCountryCode(e.target.value);
          if(onCountryChange){
            const fullNameEl = document.getElementById(`country_${e.target.value}`)
            onCountryChange!({countryCode: e.target.value as CountryCode, fullName: fullNameEl?.textContent!})
          }
          //console.log(`you selected ${e.target.value}`)
        }}
        style={{
          borderColor: "#979bb5",
          borderWidth: 1,
          borderRadius: 16,
          padding: 10,
          paddingLeft: 30,
          height: 60,
          width: "80%",
          marginTop: 6,
          fontFamily: "regular",
          fontSize: "0.95em",
          backgroundColor: "white",
          color: "#818194",
          appearance: "none",
        }}
        className={
          errors[id]
            ? "shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-1 border-red-600"
            : "shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker mb-1"
        }
      >
        <option value={countryCode ? countryCode : ""}>{countryName ? countryName : "Select a country"}</option>
        {countries.map((country: any) => (
          <option key={country.cca2} id={`country_${country.cca2}`} value={country.cca2} >
            {country.name.common}
          </option>

        ))}
      </select>
      {errors[id] && showValidationErrors && (
        <p className="text-red-600 text-sm mt-1">{errors[id]?.message}</p>
      )}
    </div>
  );
}
