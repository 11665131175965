import React, { ReactElement, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import {
  DeepMap,
  FieldValues,
  FieldError,
  UseFormSetValue,
} from "react-hook-form";
import { CustomImage } from "../../dto/CustomImage";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/swiper-bundle.min.css";
import { MdLineAxis } from "react-icons/md";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface CustomProps<T extends FieldValues> {
  uploadId: string;
  addImages: Function;
  removeImage: Function;
  register: Function;
  errors: DeepMap<FieldValues, FieldError>;
  validation?: FieldValues;
  setValue?: UseFormSetValue<T>;
  placeHolderImage?: string;
  rounded?: boolean;
  custom: boolean;
  maxItems: number;
  initialImages: any[];
}

export default function CustomDropZone<T extends FieldValues>({
  uploadId,
  addImages,
  removeImage,
  register,
  errors,
  validation,
  setValue,
  placeHolderImage,
  rounded,
  initialImages,
  maxItems,
  custom,
}: CustomProps<T>): ReactElement {
  const [selectedImages, setImages] = useState<any[]>(initialImages);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      const temp = acceptedFiles.map((file: File) => {
        return Object.assign(file, {
          id: uuidv4(),
          preview: URL.createObjectURL(file),
        });
      });

      if (maxItems === 1 && temp.length === 1) addImage(temp);
      else if (
        selectedImages.length < maxItems &&
        temp.length + selectedImages.length <= maxItems
      )
        addImage(temp);
      else console.log(`You cannot add more than ${maxItems} image(s)`);
    },
    noClick: true, // Prevent file dialog from opening when dropzone is clicked
    noKeyboard: true, // Prevent file dialog from opening when dropzone is focused and spacebar or enter key is pressed
  });

  const addImage = async (newImages: File[]) => {
    if (maxItems > 1) {
      const currentlySelected = [...newImages, ...selectedImages];
      setImages(currentlySelected);
    } else setImages(newImages);
    addImages(newImages, uploadId);
  };

  const handleRemoveImage = (imageId: number, imageLink: string) => {
    const updatedImages = selectedImages.filter(img => img.preview !== imageLink);
    setImages(updatedImages);

    removeImage(imageLink);
};



  useEffect(() => {
    if (selectedImages?.length === 0) {
      setImages(initialImages!);
    }
    return () => selectedImages.forEach(image => URL.revokeObjectURL(image.preview));
  }, [initialImages, selectedImages]);

  return (
    <div
      {...getRootProps()}
      className="flex items-center justify-center flex-col  w-[100%] overflow-hidden"
    >
      <input {...getInputProps()} />
      <>
        {custom ? (
          <>
            {selectedImages?.length <= 1 ? (
              <div className="relative">
                {custom && selectedImages.length === 1 && !rounded && (
                  <FontAwesomeIcon
                    size="lg"
                    className="absolute top-8 right-0 mt-2 mr-2 cursor-pointer text-red-600"
                    onClick={() => {
                      setImages([]);
                    }}
                    icon={faTrash as IconProp}
                  />
                )}
                <img
                  onClick={open}
                  className={`w-[250px] h-[250px] ${
                    rounded ? "object-cover" : "object-contain"
                  } cursor-pointer ${
                    rounded && selectedImages.length !== 0 ? "rounded-full" : ""
                  }`}
                  src={selectedImages[0]?.preview || placeHolderImage}
                  alt="Dropzone custom"
                />
              </div>
            ) : (
              <div className="p-4  flex justify-center align-center max-w-[250px] max-h-[250px]">
                <Swiper
                  modules={[Navigation, Pagination, A11y]}
                  spaceBetween={50}
                  draggable={true}
                  speed={1000}
                  autoplay={true}
                  slidesPerView={1}
                  navigation={true}
                  pagination={{ clickable: true }}
                  centeredSlides
                  onSlideChange={() => console.log("slide change")}
                >
                  {selectedImages?.map((anImage, idx) => (
                    <SwiperSlide key={idx}>
                      <div className="flex justify-center">
                        <FontAwesomeIcon size="lg"
                          className="absolute top-0 right-4 cursor-pointer text-red-600"
                          onClick={() => {
                            const updatedImages = selectedImages.filter(
                              (thisImg) => thisImg.id !== anImage.id
                            );
                            removeImage(updatedImages, uploadId);
                            setImages(updatedImages);
                          }}
                          icon={faTrash as IconProp}
                        />
                        <img
                          onClick={open}
                          className={`object-cover cursor-pointer ${
                            anImage?.preview && rounded ? "rounded-full" : ""
                          }	`}
                          src={anImage?.preview}
                          alt="Dropzone custom"
                        />
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )}

            <p className="text-center text-sm">
              Drop images to upload or{" "}
              <span
                onClick={open}
                className="underline text-blue-400 cursor-pointer"
              >
                browse your folders
              </span>
            </p>
          </>
        ) : (
          <div
            className="border-8 border-dashed flex items-center justify-center flex-col text-gray-400 cursor-pointer w-full overflow-auto"
            onClick={open}
          >
            <p>Sleep bestanden naar hier of klik om bestanden te selecteren.</p>
            <div className="w-full flex p-4">
              {selectedImages?.map((image, idx) => (
                <div
                  key={image.id}
                  className="border-4 ml-4 relative w-48 flex justify-center items-center"
                >
                  <FontAwesomeIcon 
                    className="absolute top-2 right-2 cursor-pointer text-red-600"
                    onClick={(event) => {
                      handleRemoveImage(image.id, image.preview);
                    }}
                    icon={faTrash as IconProp}
                  />
                  <img key={image.id} src={image.preview} alt="" />
                </div>
              ))}
            </div>
            {errors[uploadId] && (
              <span className="text-red-600 text-sm">
                {errors[uploadId].message}
              </span>
            )}
          </div>
        )}
      </>
    </div>
  );
}
