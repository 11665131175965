import React, { ReactElement, useEffect } from "react";
import { DeepMap, FieldError, FieldValues } from "react-hook-form";

export interface IProps {
  label: string;
  currentValue?: string;
  id: string;
  type?: string;
  register: Function;
  errors: DeepMap<FieldValues, FieldError>;
  validation?: FieldValues;
  setValue?: Function;
}
export default function TextArea({
  label,
  id,
  currentValue,
  register,
  errors,
  validation,
  setValue
}: IProps): ReactElement {

  useEffect(() => {
    if (currentValue !== undefined) {
      if(setValue){
        setValue(id, currentValue);
      }
    }
  }, [currentValue, setValue, id]);
  
  return (
    <div className="flex flex-col mb-4">
      <label className="color-gray" style={{fontFamily: 'regular', fontSize: "1em"}} htmlFor={id}>
        {validation?.required ? (
          <div>
            {label}
            <span className="text-red-600">*</span>
          </div>
        ) : (
          label
        )}
      </label>
      <textarea
        {...register(id, { ...validation })}
        placeholder={label}
        id={id}
        defaultValue={currentValue}
        className={
          errors[id]
            ? "shadow appearance-none border rounded w-full h-40 py-2 px-3 text-grey-darker mb-1 border-red-600"
            : "shadow appearance-none border rounded w-full h-40 py-2 px-3 text-grey-darker mb-1"
        }
        style={{borderColor: "#979bb5", borderWidth: 1, borderRadius: 16, padding: 10, paddingLeft: 30, height: 120, width: "80%", marginTop: 6, fontFamily: "regular", fontSize: "0.95em", color: "#818194"}}
      ></textarea>
      {errors[id] && (
        <span className="text-red-600 text-sm">{errors[id].message}</span>
      )}
    </div>
  );
}
