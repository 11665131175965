import { eventClient } from "./HttpClients";
import MasterDataDto from "../dto/MasterData.dto";
import Event from "../dto/Event.dto";
import { EmotionDto } from "../dto/Emotion.dto";
import EventBookingDto from "../dto/EventBooking.dto";

export default class EventService {
  public static async getCategories(): Promise<MasterDataDto[]> {
    try {
      let response = await eventClient.get<MasterDataDto[]>(`/categories`);
      return response.data;
    } catch (error) {
      console.error("Error in getCategories", error);
      throw error;
    }
  }

  public static async createNewEvent(newEvent: FormData): Promise<any> {
    try {
      const response = await eventClient.post<void>(`/`, newEvent, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to "multipart/form-data"
        },
      });
      return response.data    
    } catch (error) {
      console.error("Error in createNewEvent", error);
      throw error;
    }
  }
  
  public static async updateEvent(updateEvent: FormData, id:string): Promise<any> {
    try {
      const resp = await eventClient.patch<void>(`/${id}`, updateEvent);
      return resp.data;
    } catch (error) {
      console.error("Error in updateEvent", error);
      throw error;
    }
  }

  public static async getAllEvents(): Promise<Event[]> {
    try {
      let response = await eventClient.get<Event[]>("/");
      return response.data;
    } catch (error) {
      console.error("Error in getAllEvents", error);
      throw error;
    }
  }

  public static async getEventOnId(id: number): Promise<Event> {
    try {
      let response = await eventClient.get<Event>(`/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error in getEventOnId", error);
      throw error;
    }
  }

  public static async getAllEmotions(): Promise<EmotionDto[]> {
    try {
      let response = await eventClient.get<EmotionDto[]>('/emotions');
      return response.data;
    } catch (error) {
      console.error("Error in getAllEmotions", error);
      throw error;
    }
  }

  public static async getBookings(): Promise<EventBookingDto[]> {
    try{
      let response = await eventClient.get<EventBookingDto[]>("/bookings");
      return response.data;
    } catch(error) {
      console.error("Error in getting the bookings", error);
      throw error;
    }
  }
}
