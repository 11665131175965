import { authClient, setBearerToken, storeClient, storeClient2 } from "./HttpClients";
import jwt from "jwt-decode";
import TokenUser from "../dto/TokenUser.dto";
import { UserSignUpDto } from "../dto/UserSignUp.dto";
import StoreSignUpDto from "../dto/StoreSignUp.dto";
import { VerifyStoreDTO } from "../dto/VerifyStore.dto";

interface JwtResponse {
  accessToken: string;
}

export default class AuthService {

  public static async signUp(payload: UserSignUpDto){
    try {
      const response = await authClient.post("/signup", payload);
      return response.data.accessToken;
    } catch (e: any) {
      console.log(e.response.data);
    }
  }

  public static async storeSignUp(payload: StoreSignUpDto){
    try {
      const response = await storeClient.post("/", payload);
      return response.data;
    } catch(e: any) {
      console.log(e);
    }
  }

  public static async cancelSignUp(token: string){
    try {
      await authClient.delete("/signup", {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
    } catch (e: any) {
      console.log(e.response);
    }
  }

  public static async checkExistingUser(payload: UserSignUpDto){
    try {
      const result = await authClient.post("/check", payload);
      return result.data.exists;
    } catch (e: any) {
      console.log(e.response);
    }
  }
  
  public static async verifyStore(payload: FormData){
    try {
      const response = await storeClient2.post("/verify", payload);
      return response.data;
    } catch(e: any) {
      console.error(e);
    }
  }

  public static async login(
    email: string,
    password: string
  ): Promise<TokenUser> {
    try {
      let response = await authClient.post<JwtResponse>("/signin", {
        email,
        password,
      });
      let userToken = response.data.accessToken;
      let user: TokenUser = jwt(userToken);
      localStorage.setItem("userToken", userToken);
      return user;
    } catch (error) {
      console.error('Failed to login:', error);
      throw error;
    }
  }
  
}
