import { ReactElement } from 'react';
import logo from '../../assets/yaldo_text_white.svg';
import logoBlue from '../../assets/yaldo_text_main_color.svg';

interface IProps {
  secondary?: boolean;
}

export default function WaitingScreenScene({
  secondary,
}: IProps): ReactElement {
  if (secondary) {
    return (
      <div className="w-full h-full flex justify-center items-center transition duration-500 ease-in-out">
        <div className="w-52 text-center text-yaldoBlue">
          <img
            src={logoBlue}
            alt="Yaldo logo"
            width="100%"
            className="mb-6 animate-bounce"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="w-full h-full bg-yaldoBlue flex justify-center items-center transition duration-500 ease-in-out">
        <div className="w-52 text-center text-white">
          <img
            src={logo}
            alt="Yaldo logo"
            width="100%"
            className="mb-6 animate-bounce"
          />
        </div>
      </div>
    );
  }
}
