import React, { ReactElement } from "react";
import { DeepMap, FieldError, FieldValues } from "react-hook-form";

export interface ISelectOption {
  label: string;
  value: string | undefined;
}

export interface IProps {
  example: string;
  label: string;
  id: string;
  options: ISelectOption[];
  register: Function;
  errors: DeepMap<FieldValues, FieldError>;
  validation?: FieldValues;
  currentValue?: string | number | undefined;
}
export default function Input({
  example,
  label,
  id,
  currentValue,
  options,
  register,
  errors,
  validation,
}: IProps): ReactElement {
  return (
    <div className="flex flex-col mb-4">
      <label className="color-gray" style={{fontFamily: 'regular', fontSize: "1em"}} htmlFor={id}>
        {validation?.required ? (
          <div>
            {label}
            <span className="text-red-600">*</span>
          </div>
        ) : (
          label
        )}
      </label>
      <select
        id={id}
        {...register(id, { ...validation })}
        defaultValue={currentValue}
        className={
          errors[id]
            ? "shadow border rounded w-full py-2 px-3 text-grey-darker mb-1 cursor-pointer border-red-600"
            : "shadow border rounded w-full py-2 px-3 text-grey-darker mb-1 cursor-pointer"
        }
        style={{borderColor: "#979bb5", backgroundColor: "white", color: "#818194", borderWidth: 1, borderRadius: 16, padding: 10, paddingLeft: 30, height: 60, width: "80%", marginTop: 6, fontFamily: "regular", fontSize: "0.95em"}}
      >
        <option value={undefined}>{example}</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[id] && (
        <span className="text-red-600 text-sm">{errors[id].message}</span>
      )}
    </div>
  );
}
