import { Colors } from "./Colors";


interface Props {
    children?: any;
    backgroundColor?: Colors;
    textColor?: Colors;
    text: string;
    // onClick?: Function;
}

export default function InfoButton({children, backgroundColor, textColor, text}: Props) {
    return(
            <a href="mailto:team@yaldo.io" style={{height: 44, display: "flex", flexDirection: "row", justifyContent: "space-evenly", alignItems: "center", gap: 15, padding: "0px 20px", backgroundColor: backgroundColor, borderRadius: 20, color: textColor}}>
                {children}
                <p style={{fontFamily: "semiBold", textAlign: "center", margin: 0}}>{text}</p>
            </a>
    )
}