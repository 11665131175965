/* eslint-disable @typescript-eslint/no-unused-vars */
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "../forms/Input";
import CSS from 'csstype';
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Checkbox } from "../forms/Checkbox";
import UpdateUser from "../../dto/UpdateUser.dto";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useState, useEffect } from "react";
import UpdateStore from "../../dto/UpdateStore.dto";
import { useUser } from "../../context/UserContext";
import StoreService from "../../services/StoreService";

type BusinessInfo = {
  yaldoName: string;
  companyName: string;
  iban: string;
  vat: string;
  bicc: string;
  companyRepresentative: string;
  terms: boolean;
}

export const EditBusiness = () => {
    const {
        register,
        formState: { errors }, watch, reset
      } = useForm<BusinessInfo>({ mode: "onChange" });
      const [userInput, setUserInput] = useState<BusinessInfo>();
      const [userInputValid, setUserInputValid] = useState<boolean>(false);
      const { state } = useUser();

      const watchFields = watch();

      const navigate = useNavigate(); 

      useEffect(() => {
        const storeData = state?.stores
        console.log(storeData)
        const {yaldoName, vat, companyName, companyRepresentative, bicc, iban} = storeData!
        reset({yaldoName, vat, companyName, companyRepresentative, bicc, iban});
      }, [])

    const onSubmit: SubmitHandler<any> = async (e:any) => {
      e.preventDefault()
      const { terms , ...payload } = userInput!;
      const response = await StoreService.updateStore(payload, state?.stores!)
      console.log(response)

    }

      const handleUserInput = async(data: BusinessInfo) => {
        setUserInput((prevState) => ({...prevState, ...data}))
      }

    useEffect(() => {
      handleUserInput(watchFields);
    }, [JSON.stringify(watchFields)]);


    return(
        <main>
            <h1 style={{ fontFamily: "regular", textAlign: "center", marginTop: "2%", marginBottom: "5%" }}>Fill in your <b style={{fontFamily: "semiBold"}}>business</b> information</h1>
            <form onSubmit={onSubmit} style={{width: "95%", margin: "auto"}}>
              <div style={WidthInputWrapper}>
                  <Input label={"yaldo naam (Deze naam zal getoond worden bij jouw yaldo's"} id={"yaldoName"} type="text" register={register} errors={errors} validation={{
                      required: "Dit veld is verplicht"
                  }} />
                  <Input label={"VAT"} id={"vat"} type="text" register={register} errors={errors} validation={{
                      required: "Dit veld is verplicht"
                  }}/>
              </div>
              <div style={WidthInputWrapper}>
                  <Input label={"Bedrijfsnaam"} id={"companyName"} type="text" register={register} errors={errors} validation={{
                      required: "Dit veld is verplicht"
                  }}/>

                  <Input label={"Bedrijfsverantwoordelijke"} id={"companyRepresentative"} type="text" register={register} errors={errors} validation={{
                      required: "Dit veld is verplicht"
                  }}/>
              </div>
              <div style={WidthInputWrapper}>
                  <Input label={"BICC"} id={"bicc"} type="text" register={register} errors={errors} validation={{
                      required: "Dit veld is verplicht"
                  }}/>

                  <Input label={"IBAN"} id={"iban"} type="text" register={register} errors={errors} validation={{
                      required: "Dit veld is verplicht"
                  }}/>
              </div>
              <div className="w-80 flex items-center justify-start" style={{ flexWrap: "nowrap" }}>
                <Checkbox
                  id={"terms"}
                  register={register}
                  errors={errors}
                  validation={{
                    required: "Dit veld is verplicht",
                  }}
                />
                <p className="ml-2">
                  Please accept our
                  <Link
                    to={{ pathname: "https://www.google.com/" }}
                    target="_blank"
                    style={{ color: "blue", textDecoration: "underline" }}
                  >
                    {" "}
                    terms and condition
                  </Link>
                  .
                </p>
              </div>
                  <div className="w-full h-full flex items-end justify-center">
                      <div className="flex justify-between absolute" style={{width: "350px", margin: "auto", bottom: "10%"}}>
                          <button
                              className="bg-yaldoWhite text-yaldoDarkBlue hover:bg-yaldoDarkBlue hover:text-white border border-yaldoDarkBlue flex justify-between items-center"
                              style={{ padding: "10px 40px", borderRadius: 20 }}
                              type="submit"
                          >
                              <FontAwesomeIcon icon={faBookmark as IconProp} className="mr-2" />
                              <span>Opslaan</span>
                          </button>


                              <button
                              className="bg-yaldoWhite text-logout hover:bg-cancel hover:text-white border border-cancel"
                              style={{padding: "10px 40px", borderRadius: 20}}
                              type="button"
                              // onClick={() => history.replace("/events")}
                              onClick={() =>navigate("/events")}
                              >
                              Annuleren
                              </button>
                      </div>
                  </div>
            </form>
        </main>
    )
}

const WidthInputWrapper : CSS.Properties = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexGrow: 1
}   